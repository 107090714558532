
export const SET_IS_PROCESSING = 'SET_IS_PROCESSING';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export const SUBMIT_DOMAIN_FORM = 'SUBMIT_DOMAIN_FORM';
export const REQUEST_DOMAIN_SUCCESS = 'REQUEST_DOMAIN_SUCCESS';
export const SUBMIT_DOMAIN_FORM_FAILURE = 'SUBMIT_DOMAIN_FORM_FAILURE';

export const SUBMIT_LOGIN_SUCCESS_EXPIRED_OR_LOCKED_ACCOUNT = 'SUBMIT_LOGIN_SUCCESS_EXPIRED_OR_LOCKED_ACCOUNT';
export const SUBMIT_LOGIN_SUCCESS_EXPIRED_PASSWORD ='SUBMIT_LOGIN_SUCCESS_EXPIRED_PASSWORD';

export const SUBMIT_LOGIN_FORM = 'SUBMIT_LOGIN_FORM';
export const SUBMIT_LOGIN_FORM_SUCCESS = 'SUBMIT_LOGIN_FORM_SUCCESS';
export const SUBMIT_LOGIN_FORM_FAILURE = 'SUBMIT_LOGIN_FORM_FAILURE';

export const SUBMIT_FORGOT_PASSWORD_FORM = 'SUBMIT_FORGOT_PASSWORD_FORM';
export const SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS = 'SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS';

export const SET_COUNTRY_OPTIONS = 'SET_COUNTRY_OPTIONS';
export const SET_STATE_OPTIONS = 'SET_STATE_OPTIONS';

export const SET_SPLASH = 'SET_SPLASH';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

export const SET_DOMAIN_LOGO = 'SET_DOMAIN_LOGO';