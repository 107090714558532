import React from "react";
import ErrorMessage from "../Components/ErrorMessage";
import InfoMessage from "../Components/InfoMessage";
import bundle from "../config";

class BasicPage extends React.Component {
    getErrors() {
        const { appData } = this.props;

        if(appData.get('errors').size > 0) {
            let jsx = appData.get('errors').map((x, i) => {
                return <ErrorMessage message={x} key={i} />
            });
            return <div className='login_form_errors'>{jsx}</div>
        }
    }

    getInfoMessages() {
        const { appData } = this.props;

        if (appData.get('info').size > 0) {
            let jsx = appData.get('info').map((x,i) => {
                return <InfoMessage message={x} key={i} />
            });
            return <div className='login_info_messages'>{jsx}</div>
        }
    }

    renderLoader() {
        const { appData } = this.props;

        if (appData.get('isProcessing')) {
            return <div className="loaderContainer"><div className="loader" /></div>;
        }
    }

    getLogo() {
        const { userData, appData } = this.props;

        if (userData.get('logo')) {
            return bundle.images[userData.get('logo')] || userData.get('logo');
        } else {
            return bundle.images[appData.get('logo')] || appData.get('logo');
        }
    }

    render(){
        const { appData, userData, title } = this.props;
        return (
            <div id="pageContainer">
                {this.renderLoader()}
                <div className="logo_container">
                    <img src={this.getLogo()} alt="Logo" />
                </div>
                <h4>{title}</h4>

                {this.getInfoMessages()}
                {this.getErrors()}
                {this.props.children}
            </div>
        )
    }
}

export default BasicPage;