import React from "react";
import PropTypes from 'prop-types';

import IconSpan from "../IconSpan";
import TextBox from "./TextBox";

const TextBoxWithIcon = props => {
    return (
        <div className={"input-icon-container"}>
            <IconSpan iconClass={"input-icon " + props.iconClass} ></IconSpan>
            <TextBox {...props} />
        </div>
    )
};

TextBoxWithIcon.propTypes = {
  iconClass: PropTypes.string.isRequired
}

export default TextBoxWithIcon;
