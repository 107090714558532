import React from "react";

export const OffsetPanelLayout = (props) => {
    return (
        <div className="login-layout-offset">
            <div className="login-layout-offset-left">
                { props.message.body &&
                <div className="login-layout-offset-left-message">
                    <div className="message-box">
                        <div className="message-box__header">
                            <h4>{props.message.title}</h4>
                        </div>
                        <div className="message-box__body">
                          <p>{props.message.body}</p>
                        </div>
                    </div>
                </div>
                }
            </div>
            <div className="login-layout-offset-right">
                <div className="login-layout-offset-right-body">
                    {props.children}
                </div>
            </div>
        </div>
    );
};