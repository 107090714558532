import React from "react";

import Button from '../Components/Button';
import TextBoxWithIcon from "../Components/Inputs/TextBoxWithIcon";

import BasicPage from "../Layouts/BasicPage";
import * as actionTypes from "../constants/constants";
import TextArea from "../Components/Inputs/TextArea";

class DomainOnly extends React.Component {
    constructor(props) {
        super(props);

        // Initial State
        this.state = {
            domainInputValue: props.userData.get('domain') || '',
            envKey: ""
        }

        this.handleDomainInput = this.handleDomainInput.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        var that = this;
        fetch('config.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                 that.setState({ envKey: myJson.environmentvalue });
            });
    }


    handleDomainInput(e) { this.setState({ domainInputValue: e.target.value }) }

    handleFormSubmit(e) {
        e.preventDefault();
        const { actions } = this.props;
        actions.requestDomain(this.state.domainInputValue);
    }

    render() {


        const { appData, userData, actions } = this.props;
        let { envKey } = this.state;
        let env = actionTypes.environmentLevelLists.find(a => a.key == envKey);
        let data = "";
        if (env) data = env.value;
        data = data == "" ? "Siterra" : data;
        let toggle_sp49484 = userData.get('toggle').toJS()["SP_49484"];
        return (
            <BasicPage {...{ appData, userData, actions }}>
                <form onSubmit={this.handleFormSubmit}>
                    {toggle_sp49484 &&
                        <div
                            disabled={appData.get('isProcessing')}
                            className="aur-text primary"
                        >
                            {data}
                        </div>}
                    <TextBoxWithIcon
                        id="domain"
                        iconClass="fa fa-list-alt"
                        placeholder="Domain"
                        className="aur-text-box"
                        onChange={this.handleDomainInput}
                        defaultValue={userData.get('domain')} // changeme
                    />

                    <Button
                        type='submit'
                        disabled={appData.get('isProcessing')}
                        className="aur-button primary" >Continue</Button>
                </form>
            </BasicPage>
        );
    }
}

export default DomainOnly;