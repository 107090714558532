const domainIdResponse = {
    data: {
        "DomainId": 1005475,
        "Name": "Orbitel",
        "ImagePrefix": "Orbitel"
    }
};

const badLoginCredsResponse = {
    data: {
        "RedirectUrl": null,
        "AccountStatus": null,
        "AreHintsNeeded": false,
        "IsSplashNeeded": false,
        "SplashContent": null,
        "IsSuccess": false,
        "Errors": [
            {
                "PropertyName": null,
                "ErrorMessage": "User credentials were invalid"
            }
        ]
    }
};

const goodLoginCredsResponse = {
    data: {
        "RedirectUrl": "/gisapi.dll?do=output&pageid=1032000&classid=1000000&objectid=1",
        "AccountStatus": "ACTIVE",
        "AreHintsNeeded": false,
        "IsSplashNeeded": true,
        "SplashContent": "<div style=\"text-align:center;\"><br/><img src=\"images/Siterra-Logo.gif\" alt=\"Siterra Logo\" />\n    <font face=\"arial\"><h1>Scheduled Maintenance Notification</h1>\n    <h3>Sunday July 13, 12:00 AM CDT<br/>To<br/>Sunday July 13, 4:30 AM CDT</h3>\n    Siterra will be unavailable for use during this maintenance window.<br/><br/>If you have any questions regarding this planned maintenance, please contact your<br/>Siterra administrator or Siterra Support.\n</font></div>",
        "IsSuccess": true,
        "Errors": []
    }
};

const expiredPasswordResponse = {
    data: {
        "RedirectUrl": null,
        "AccountStatus": "EXPIRED_PASSWORD",
        "AreHintsNeeded": false,
        "IsSplashNeeded": false,
        "SplashContent": null,
        "IsSuccess": false,
        "Errors": [
            {
                "PropertyName": null,
                "ErrorMessage": "Password needs to be changed."
            }
        ]
    }
};

const expiredAccountResponse = {
    data: {
        "RedirectUrl": null,
        "AccountStatus": "EXPIRED_ACCOUNT",
        "AreHintsNeeded": false,
        "IsSplashNeeded": false,
        "SplashContent": null,
        "IsSuccess": false,
        "Errors": [
            {
                "PropertyName": null,
                "ErrorMessage": "This account is expired."
            }
        ]
    }
};

const firstTimeResponse = {
    data: {
        "RedirectUrl": null,
        "AccountStatus": "EXPIRED_PASSWORD",
        "AreHintsNeeded": true,
        "IsSplashNeeded": false,
        "SplashContent": null,
        "IsSuccess": false,
        "Errors": [
            {
                "PropertyName": null,
                "ErrorMessage": "Password needs to be changed."
            }
        ]
    }
};

const lockedAccountResponse = {
    data: {
        "RedirectUrl": null,
        "AccountStatus": "LOCKED",
        "AreHintsNeeded": false,
        "IsSplashNeeded": false,
        "SplashContent": null,
        "IsSuccess": false,
        "Errors": [
            {
                "PropertyName": null,
                "ErrorMessage": "This account has been locked."
            }
        ]
    }
};

const sendLockedAccountFormResponse = {
    data: { 
        "IsSuccess": true,
        "SuccessMessage": "Your request has been submitted to your administrator." 
    }
};

const sendForgotPasswordFormResponse = {
    data: { 
        "IsSuccess": true, 
        "SuccessMessage": "Please provide the answer to your Security Question.",
        "SecurityQuestionText": "What is your favorite color?"
    }
};

const sendForgotPasswordFormErrorResponse = {
    data: {
        "IsSuccess": false,
        "Errors": [
            { "ErrorMessage": "No account was found matching the last name and email provided." }
        ]
    }
}

const sendForgotPasswordStep2FormResponse = {
    data: { 
        "IsSuccess": true, 
        "SuccessMessage": "An email has been sent containing your password."
    }
};

const sendResetPasswordFormResponse = {
    data: {
        "IsSuccess": true,
        "SuccessMessage": "Your password has been reset."
    }
};

const sendAccountApplicationFormResponse = {
    data: {
        "IsSuccess": true,
        "SuccessMessage": "Thank you, your request to access Siterra has been sent.  You will be notified via email when your request has been processed.  Please allow up to 3 business days for a response."
    }
}

const countriesResponse = {
    data: {
        Locations: [
            {Id: 1, Value: "Test Country"}
        ]
    }
}

const statesResponse = {
    data: {
        Locations: [
            {Id: 1, Value: "Test State 1"},
            {Id: 2, Value: "Test State 2"}
        ]
    }
}


const getLoginResponse = username => {
    switch(username) {
        case "good": return goodLoginCredsResponse;
        case "expiredPassword": return expiredPasswordResponse;
        case "expiredAccount": return expiredAccountResponse;
        case "firstTime": return firstTimeResponse;
        case "lockedAccount": return lockedAccountResponse;
        default: return badLoginCredsResponse;
    }
}

const apiRoutes = {
    "/domains": () => {
        return domainIdResponse;
    },
    "/session": postData => {
        return getLoginResponse(postData.Username);
    },
    "/accountrequests": postData => {
        return sendAccountApplicationFormResponse;
    },
    "/forgotpassword": postData => {
        if(postData.LastName === "noMatch") return sendForgotPasswordFormErrorResponse;
        return sendForgotPasswordFormResponse;
    },
    "/users/ResetPassword": postData => {
        return sendForgotPasswordStep2FormResponse;
    },
    "/users/Security": postData => {
        return sendResetPasswordFormResponse;
    },
    "/countries": () => {
        return countriesResponse;
    },
    "/countries/1/states": () => {
        return statesResponse;
    },
    "/unlockrequest": () => {
        return sendLockedAccountFormResponse;
    }
}

export class RequestManager {
    static getEntity(url) {
        let apiUrl = url.split("?")[0];
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let val;
                try{
                    val = apiRoutes[apiUrl]();
                } catch(e) {
                    reject({response: e});
                }
                resolve(val);
            }, 2000)
        });
    }

    static postEntity(url, data) {
        let apiUrl = url.split("?")[0];
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let val;
                try {
                    val = apiRoutes[apiUrl](data);
                    if(!val.data.IsSuccess) throw(val);
                } catch(e) {
                    reject({response: e});
                }
                resolve(val)
            }, 2000);
        });
    }
}