import React from "react";
import PropTypes from "prop-types";

import IconSpan from "../Components/IconSpan";

const InfoMessage = props => (
    <div className="login_info_div">
        <IconSpan iconClass="fa fa-info-circle" />
        {props.message}
    </div>
);

InfoMessage.propTypes = {
    message: PropTypes.string.isRequired
}

export default InfoMessage;