import * as actionTypes from '../../constants/constants';
import requestManager from '../../requestManager';

import * as appActions from '../../stores/appActions';
import * as siterraActionTypes from "./constants";

import config from './siterra.config';
import ToggleService from "siterra_toggle_service";

const BASE_URL = config.urls.base;
const TOGGLES_URL = config.urls.toggles;

const validateFields = (fieldList, state) => {
    return fieldList.filter(x => (!state[x] || state[x] === ""))
        .map(x => {
            let fieldName = x.split("_").map(x => `${x.slice(0, 1).toUpperCase()}${x.slice(1, x.length).toLowerCase()}`).join(" ");
            return `${fieldName} cannot be empty.`;
        })
}

const validateFieldsBasic = (fieldList, state) => {
    return fieldList.filter(x => (!state[x] || state[x] === ""));
}

// Remove this function when removing the toggle for SP_47149
const validateEmailAddress_pvSP_47149 = (email) => {
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
}

const validateEmailAddress = (email) => {
    // Perform the most minimal email validation here, any further validation will be handled by the backend.
    // Email address must be of the form a@b:
    // * there must be at least one character before the @
    // * there must be at least one character after the @
    // * the character @ is required
    var emailRegex = /^.+\@.+$/;
    return emailRegex.test(email);
}

const camelize = str => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index == 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

function setDomainLogo(imagePrefix) {
    const imagePath = `./Images/${imagePrefix}-logo.gif`;
    return {
        type: siterraActionTypes.SET_DOMAIN_LOGO,
        logo: imagePath
    }
}

function setCountryOptions(countryOptions) {
    return {
        type: siterraActionTypes.SET_COUNTRY_OPTIONS,
        countryOptions
    }
}

function setStateOptions(stateOptions) {
    return {
        type: siterraActionTypes.SET_STATE_OPTIONS,
        stateOptions
    }
}

function setSplash(splash) {
    return {
        type: siterraActionTypes.SET_SPLASH,
        splash
    }
}

function setRedirectUrl(redirectUrl) {
    return {
        type: siterraActionTypes.SET_REDIRECT_URL,
        redirectUrl
    }
}

function setSsoLogin(ssoBtnVisible, ssoAuthUrl, defaultSsoUrl) {
    return {
        type: siterraActionTypes.SET_SSO_LOGIN,
        ssoBtnVisible,
        ssoAuthUrl,
        defaultSsoUrl
    }
}

function setErrorFields(errorFields) {
    return {
        type: siterraActionTypes.SET_ERROR_FIELDS,
        errorFields
    }
}


function setToggles (toggle)  {
    return {
        type: siterraActionTypes.SET_TOGGLES,
        toggle: toggle
    }
}

// Returns an array of string error messages.
// TODO: Write some tests that test this function
function getErrors(data) {
    // This is a generic Siterra error
    // and must be handled a differently
    if (data.code) {
        return data.errors;
    }
    // If no code property present
    // access the Errors property and map ErrorMessages.
    else {
        if (Array.isArray(data.Errors)) return data.Errors.map(x => x.ErrorMessage);
        if (typeof data.Errors === 'string') return [data.Errors];
    }
}

function getDomainFromUrl() {
    let url = window.location.host;
    let potentialDomain = url.split('.')[0];
    if (potentialDomain === 'www') return null;
    if (potentialDomain === 'localhost') return null; // For devs
    return potentialDomain;
}

function sendToOldLogin() {
    window.location.href = config.urls.oldLogin;
}

export function getCookie(name) {
    let match = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (match) return match[1];
    return null;
  }

export function setCookie(cookieName, cookieValue) {
    document.cookie = `${cookieName}=${cookieValue}; Path=/`;
}

export async function getDomainData(domainName) {
    const uri = `${BASE_URL}/domains?name=${encodeURIComponent(domainName)}`;
    try {
        let domainResponse = await requestManager.getEntity(uri);
        return {data: domainResponse.data};
    } catch(e) {
        return {error: e};
    }
}

const featureToggleService = new ToggleService();
async function getToggles () {
    try {
        const toggles = await featureToggleService.getToggles();
        return toggles;

    } catch (error) {
        console.error(error);
    }
}

function setJumpToUrl(jumpToUrl) {
    return {
        type: siterraActionTypes.SET_JUMPTO_URL,
        jumpToUrl
    }
}

export function requestDomainDataSuccess(data) {
    return dispatch => {
        let { Name, DomainId, ImagePrefix, LoginUrlPrefix, IsSsoBtnVisible, AuthUrl, DefaultSsoLoginUrl } = data;
        // Set the loginURLNew cookie to the domain-specific URL
        if(LoginUrlPrefix) {
            let urlBits = window.location.hostname.split(".");
            let newUrl = `${LoginUrlPrefix}.${urlBits.slice(1).join(".")}`;
            setCookie("loginURLNew", window.location.href.replace(window.location.hostname, newUrl));
        }

        // Successfully retrieved domain name - send to SSO login or set
        // ImagePrefix for retrieving domain-specific logo
        if (AuthUrl || DefaultSsoLoginUrl) {
            if(getCookie('deepLinkUrl')) {
                // If deepLinkUrl cookie is set, automatically redirect to SSO login
                return (window.location.href = AuthUrl);
            }
            else {
                // Store SSO login URL for redirecting after clicking SSO login button
                dispatch(setSsoLogin(IsSsoBtnVisible, AuthUrl, DefaultSsoLoginUrl));
            }
        }
        dispatch(requestDomainSuccess(Name, DomainId));
        if (ImagePrefix) dispatch(setDomainLogo(ImagePrefix));
        dispatch(appActions.changePage('login'));
    }
}

export function domainPageLoad() {
    return async dispatch => {
        // Set initial application loading state and login URL cookie
        dispatch(appActions.setIsLoadingInitialState(true));
        setCookie("loginURLNew", window.location.href);

        if(window.location.search.toLowerCase().search("jumpto") > 0) {
            dispatch(setJumpToUrl(`/jump.htm${window.location.search}`));
        }

        const toggle = await getToggles();
        dispatch(setToggles(toggle));

        // Attempt to parse domain name from URL.  If not present, send
        // to Domain login page
        let potentialDomain = getDomainFromUrl();
        if(!potentialDomain) return dispatch(appActions.changePage('domain'));

        let { data } = await getDomainData(potentialDomain);
        if(!data) return dispatch(appActions.changePage("domain"));

        dispatch(requestDomainDataSuccess(data));
    }
}

export function requestDomain(domainName) {
    return async dispatch => {
        if (!domainName || domainName === "") {
            return dispatch(appActions.formFailure(["Domain cannot be empty."]));
        }

        let { data, error } = await getDomainData(domainName);
        if(data) {
            dispatch(requestDomainDataSuccess(data));
        } else {
            if(!error.response) return dispatch(appActions.formFailure("Unable to contact server."));
            if(error.response.status === 404) {
                dispatch(appActions.formFailure("Domain not found."));
            }
            else {
                dispatch(appActions.formFailure(getErrors(error.response.data)));
            }
        }
    }
}

function requestDomainSuccess(domain, domainId) {
    return {
        type: siterraActionTypes.REQUEST_DOMAIN_SUCCESS,
        domain,
        domainId,
    }
}

function submitDomainFormFailure() {
    return {
        type: actionTypes.SUBMIT_DOMAIN_FORM_FAILURE
    }
}

function getQueryParams() {
    let qParams = {};
    window.location.search.substring(1).split('&').forEach((pair) => {
        pair = pair.split('=');
        qParams[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return qParams;
}

// ------------------
// Login Form actions
// ------------------
export function submitLoginForm(data) {
    return async (dispatch, getState) => {
        // Toggle Removal SP-45939: Remove the following two lines and the
        // submitLoginForm_sp45939 method from the bottom of this file
        var toggle_sp45939 = getState().$$dataStore.get("toggle").toJS()["SP_45939"];
        if(!toggle_sp45939) return dispatch(submitLoginForm_sp45939(data));

        const qParams = getQueryParams();
        // If data.ssoLogin is true, the SSO button was clicked
        // In that case, send the user to the ssoAuthUrl
        if(data.ssoLogin && !qParams.apimLogin) {
            return (window.location.href = getState().$$dataStore.get('ssoAuthUrl'));
        }
        
        // Form validation
        let fieldsToValidate = ["username"];
        if(!getState().$$dataStore.get('domain')) fieldsToValidate = fieldsToValidate.concat(["domain"]);
        let errs = validateFields(fieldsToValidate, data);
        if (errs.length > 0) {
            return dispatch(appActions.formFailure(errs));
        }

        let uri = `${BASE_URL}/session`;
        let mappedData = {
            Username: data.username,
            Password: data.password,
            DomainName: getState().$$dataStore.get('domain') || data.domain
        };

        // APIM Login, change URL and add query params
        if(qParams.apimLogin && qParams.apimLogin === "1") {
            const { env, delegation, returnUrl, salt, sig } = qParams;

            uri = `${uri}/Apim`;
            mappedData = { ...mappedData, Environment: env, Delegation: delegation, ReturnUrl: returnUrl, Salt: salt, Signature: sig };
        }

        try {
            const res = await requestManager.postEntity(uri, mappedData);
            if (!res.data) return dispatch(appActions.formFailure('Error contacting server.'));

            dispatch(submitLoginFormSuccess());

            var { IsSplashNeeded, SplashContent, RedirectUrl, Token, IsMfa } = res.data;
            // If this user is marked for Mfa and validated successfully, redirect to start the Mfa flow
            if (IsMfa) {
                if (!RedirectUrl) {
                    return dispatch(appActions.formFailure('Error contacting server.'));
                }
                return (window.location.href = RedirectUrl);
            }

            const jumpToUrl = getState().$$dataStore.get('jumpToUrl');
            setCookie("access_token", Token);

            // If splash screen returned. Set splash screen content
            if (IsSplashNeeded && SplashContent) {
                dispatch(setRedirectUrl(jumpToUrl || RedirectUrl));
                dispatch(setSplash(SplashContent));
                dispatch(appActions.changePage('splash'));
            }
            // Otherwise, redirect.
            else {
                if(qParams.apimLogin) {
                    var bits = RedirectUrl.split("token=");
                    if(bits.length > 1) {
                        bits[1] = encodeURIComponent(bits[1]);
                    }
                    RedirectUrl = bits.join("token=");
                }
                window.location.href = jumpToUrl || RedirectUrl;
            }
        }
        catch (err) {
            const error = err.response;
            if(!error || !error.data) return dispatch(appActions.formFailure('Error contacting servers.'));

            const { AccountStatus, AreHintsNeeded, Errors, Token } = error.data;

            // Expired Password or First Time User
            if (AccountStatus === 'EXPIRED_PASSWORD') {
                dispatch(appActions.setHintsFlag(AreHintsNeeded));

                dispatch(submitLoginSuccessWithExpiredPassword(data.username, Token));
                dispatch(appActions.changePage('resetPassword'));
                dispatch(appActions.setErrors(getErrors(error.data)));
            }

            // Expired or Locked Account
            else if (AccountStatus === 'EXPIRED_ACCOUNT' || AccountStatus === 'LOCKED') {
                dispatch(submitLoginSuccessWithExpiredOrLockedAccount(data.username));
                dispatch(appActions.changePage('lockedAccount'));
                dispatch(appActions.setErrors(getErrors(error.data)));
            }

            // SSO User
            else if (AccountStatus === 'SSO_ACCOUNT' && getState().$$dataStore.get('defaultSsoUrl')) {
                return (window.location.href = getState().$$dataStore.get('defaultSsoUrl'));
            }

            // General failure response
            else {
                dispatch(appActions.formFailure(getErrors(error.data)));
            }
        }
    }
}

function submitLoginFormSuccess() {
    return {
        type: actionTypes.SUBMIT_LOGIN_FORM_SUCCESS,
    }
}

function submitLoginSuccessWithExpiredPassword (username, token) {
    return {
        type: actionTypes.SUBMIT_LOGIN_SUCCESS_EXPIRED_PASSWORD,
        username,
        token
    }
}

function submitLoginSuccessWithExpiredOrLockedAccount (username) {
    return {
        type: actionTypes.SUBMIT_LOGIN_SUCCESS_EXPIRED_OR_LOCKED_ACCOUNT,
        username
    }
}


// -----------------------
// Forgot Password actions
// -----------------------
export function submitForgotPasswordForm(data) {
    return async (dispatch, getState) => {
        const url = `${BASE_URL}/forgotpassword`;
        const toggleValue = await featureToggleService.getToggle("SP_38706");
        if (toggleValue) {
            let errs = validateFields(['lastName', 'email',], data);
            if (errs.filter(element => element.indexOf("Email") >= 0).length == 0) {
                const sp47149ToggleValue = await featureToggleService.getToggle("SP_47149");
                // When removing the toggle for SP_47149, keep everything inside this outer if, remove the outer else
                if (sp47149ToggleValue) {
                    if (!validateEmailAddress(data.email)) {
                        errs.push('Email address is not valid.');
                    }
                } else {
                    if (!validateEmailAddress_pvSP_47149(data.email)) errs.push('Email address is not valid.');
                }
            }
            if (errs.length > 0) {
                return dispatch(appActions.formFailure(errs));
            }
        } else {
            let errs = validateFields(['lastName', 'email',], data);
            if (errs.length > 0) {
                return dispatch(appActions.formFailure(errs));
            }
        }

        let mappedData = {
            DomainId: getState().$$dataStore.get('domainId'),
            LastName: data.lastName,
            EmailAddress: data.email,
        }

        try {
            const res = await requestManager.postEntity(url, mappedData);
            if (!res.data) return dispatch(appActions.formFailure('Error contacting server.'));

            const { SecurityQuestionText, SuccessMessage } = res.data;

            dispatch(submitForgotPasswordFormSuccess(data.lastName, data.email, SecurityQuestionText));
            dispatch(appActions.changePage("forgotPasswordStep2"));
            dispatch(appActions.setInfo([SuccessMessage]));
        }
        catch(err) {
            let error = err.response;
            if (!error || !error.data) return dispatch(appActions.formFailure('Error contacting server.'));

            dispatch(appActions.formFailure(getErrors(err.response.data)));
        }
    }
}

function submitForgotPasswordFormSuccess(lastName, email, hintQuestion) {
    return {
        type: actionTypes.SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS,
        lastName,
        email,
        hintQuestion
    }
}

// ----------------------
// Forgot Password Step 2 actions
// ----------------------
export function submitForgotPasswordStep2Form(data) {
    return async (dispatch, getState) => {
        const url = `${BASE_URL}/users/ResetPassword`;
        if(!data.hintAnswer || data.hintAnswer === "") return dispatch(appActions.formFailure("Hint Answer cannot be empty."));

        let mappedData = {
            DomainId: getState().$$dataStore.get('domainId'),
            LastName: getState().$$dataStore.get('lastName'),
            EmailAddress: getState().$$dataStore.get('email'),
            SecurityAnswer: data.hintAnswer,
            LoginUrl: window.location.href
        };

        try {
            const res = await requestManager.postEntity(url, mappedData);
            if (!res.data) return dispatch(appActions.formFailure('Error contacting server.'));

            dispatch(appActions.changePage('blank'));
            dispatch(appActions.setInfo(res.data.SuccessMessage));
        }
        catch(err) {
            const error = err.response;
            if (!error || !error.data) return dispatch(appActions.formFailure('Error contacting server.'));

            dispatch(appActions.formFailure(getErrors(error.data)));
        }
    }
}

// ----------------------
// Locked Account actions
// ----------------------
export function submitLockedAccountForm(data) {
    return async (dispatch, getState) => {
        const url = `${BASE_URL}/unlockrequest`;

        let fieldsToValidate = [];

        if (!getState().$$dataStore.get('username')) fieldsToValidate.push('username');

        let errs = validateFields(fieldsToValidate, data);
        if (errs.length > 0) {
            return dispatch(appActions.formFailure(errs));
        }

        let mappedData;

        data.password ?
        mappedData = {
            Username: getState().$$dataStore.get("username") || data.username,
            DomainName: getState().$$dataStore.get('domain')
        } :
        mappedData = {
            Username: getState().$$dataStore.get('username') || data.username,
            DomainName: getState().$$dataStore.get('domain')
        }

        try {
            const res = await requestManager.postEntity(url, mappedData);
            if (!res.data) return dispatch(appActions.formFailure('Error contacting server.'));

            dispatch(appActions.changePage('blank'));
            dispatch(appActions.setInfo(res.data.SuccessMessage));
        }
        catch (err) {
            const error = err.response;
            if (!error || !error.data) return dispatch(appActions.formFailure('Error contacting server.'));

            if (!error.data.IsSuccess) return dispatch(appActions.formFailure(getErrors(error.data)));
        }
    }
}


// ----------------------
// Reset Password actions
// ----------------------
export function submitResetPasswordForm(data) {
    return async (dispatch, getState) => {
        const url = `${BASE_URL}/users/Security`;
        const hintsRequired = getState().$$appStore.get('showHints');

        let fieldsToValidate = ['password', 'confirmPassword'];
        // Add hint validations if necessary
        if (hintsRequired) fieldsToValidate.push('hintQuestion', 'hintAnswer');

        let errs = validateFields(fieldsToValidate, data);
        if (data.password !== data.confirmPassword) errs.push('Password and Confirm Password must match.');
        if (errs.length > 0) return dispatch(appActions.formFailure(errs));

        let mappedData = {
            UserName: getState().$$dataStore.get('username'),
            DomainId: getState().$$dataStore.get('domainId'),
            NewPassword1: data.password,
            NewPassword2: data.confirmPassword
        };

        if (hintsRequired) {
            mappedData.PasswordHintQuestion = data.hintQuestion;
            mappedData.PasswordHintAnswer = data.hintAnswer;
        }

        try {
            const res = await requestManager.patchEntity(url, mappedData);
            if (!res.data) return dispatch(appActions.formFailure('Error contacting server.'));

            if (res.data.IsSuccess) {
                dispatch(appActions.changePage('login'));
                dispatch(appActions.setInfo('Your password has been reset. Please login using your new credentials.'));
            }
        }
        catch (err) {
            const error = err.response;
            if (!error || !error.data) return dispatch(appActions.formFailure('Error contacting server.'));

            dispatch(appActions.formFailure(getErrors(error.data)));
        }
    }
}


// ----------------------
// Account Application actions
// ----------------------
export function accountApplicationPageLoad() {
    return (dispatch, getState) => {
        dispatch(setErrorFields([]));
        let domainId = getState().$$dataStore.get('domainId');
        const url = `${BASE_URL}/countries?domainId=${domainId}`;

        requestManager.getEntity(url)
            .then(res => {
                dispatch(setCountryOptions(res.data.Locations.map(x => { return {value: x.Id, label: x.Value} })));
            });
    }
}


export function accountApplicationCountryInput(country) {
    return (dispatch, getState) => {
        let domainId = getState().$$dataStore.get('domainId');
        const url = `${BASE_URL}/countries/${country.value}/states?domainId=${domainId}`;
        requestManager.getEntity(url)
            .then(res => {
                dispatch(setStateOptions(res.data.Locations.map(x => { return {value: x.Id, label: x.Value} })));
            });
    }

}

export function submitAccountApplicationForm(data) {
    return async (dispatch, getState) => {
        const url = `${BASE_URL}/accountrequests`;

        let fieldsToValidate = ["firstName", "lastName", "email", "businessPhone", "title", "company"];

        const toggleValue = await featureToggleService.getToggle("SP_38706");
        if (toggleValue) {
            let errsFlds = validateFieldsBasic(fieldsToValidate, data);
            let errsMsgs= errsFlds.map(x => `${x.slice(0, 1).toUpperCase()}${x.slice(1, x.length)} cannot be empty.`);
            if (errsFlds.indexOf("email") < 0) {
                const sp47149ToggleValue = await featureToggleService.getToggle("SP_47149");
                // When removing the toggle for SP_47149, keep everything inside this outer if, remove the outer else
                if (sp47149ToggleValue) {
                    if (!validateEmailAddress(data.email)) {
                        errsMsgs.push('Email address is not valid.');
                        errsFlds.push('email');
                    }
                } else {
                    if (!validateEmailAddress_pvSP_47149(data.email)) {
                        errsMsgs.push('Email address is not valid.');
                        errsFlds.push('email');
                    }
                }
            }
            if (errsFlds.length > 0) {
                dispatch(appActions.formFailure(errsMsgs));
                return dispatch(setErrorFields(errsFlds));
            } else {
                dispatch(setErrorFields([]));
            }
        } else {
            let errs = validateFieldsBasic(fieldsToValidate, data);
            if (errs.length > 0) {
                dispatch(appActions.formFailure(errs.map(x => `${x.slice(0, 1).toUpperCase()}${x.slice(1, x.length)} cannot be empty.`)));
                return dispatch(setErrorFields(errs));
            } else {
                dispatch(setErrorFields([]));
            }
        }

        let mappedData = {
            DomainId: getState().$$dataStore.get('domainId'),
            FirstName: data.firstName,
            LastName: data.lastName,
            Title: data.title,
            Company: data.company,
            EmailAddress: data.email,
            MobilePhoneNumber: data.mobilePhone,
            BusinessPhoneNumber: data.businessPhone,
            CountryCode: data.country ? data.country.value : null,
            Address1: data.address1,
            Address2: data.address2,
            City: data.city,
            StateCode: data.state ? data.state.value : null,
            ZipCode: data.zipCode,
            Comments: data.comments
        };

        try {
            const res = await requestManager.postEntity(url, mappedData);
            if (!res.data) return dispatch(appActions.formFailure('Error contacting server.'));

            if (res.data.SuccessMessage) {
                dispatch(appActions.changePage("blank"));
                dispatch(appActions.setInfo(res.data.SuccessMessage));
            }
            // else
              // do we want this to do nothing? will get stuck loading if we ever get here...
        }
        catch(err) {
            const error = err.response;
            if (!error || !error.data) return dispatch(appActions.formFailure('Error contacting server.'));

            // Call formFailure with API error data
            dispatch(appActions.formFailure(getErrors(error.data)));
            let errorFields = error.data.Errors.filter(x => x.PropertyName && x.PropertyName !== "").map(x => camelize(x.PropertyName));
            if(errorFields.length > 0) dispatch(setErrorFields(errorFields));
        }
    }
}

export function submitLoginForm_sp45939(data) {
    return async (dispatch, getState) => {
        // If data.ssoLogin is true, the SSO button was clicked
        // In that case, send the user to the ssoAuthUrl
        if(data.ssoLogin && !data.apimLogin) {
            return (window.location.href = getState().$$dataStore.get('ssoAuthUrl'));
        }

        const uri = `${BASE_URL}/session${data.apimLogin === true ? '/Apim' : ''}`;
        let fieldsToValidate = ["username", "password"];
        if(!getState().$$dataStore.get('domain')) fieldsToValidate = fieldsToValidate.concat(["domain"]);
        let errs = validateFields(fieldsToValidate, data);
        if (errs.length > 0) {
            return dispatch(appActions.formFailure(errs));
        }

        let mappedData = {
            Username: data.username,
            Password: data.password,
            DomainName: getState().$$dataStore.get('domain') || data.domain,
            ...(data.env && {Environment: data.env}),
            ...(data.delegation && {Delegation: data.delegation})
        };

        try {
            const res = await requestManager.postEntity(uri, mappedData);
            if (!res.data) return dispatch(appActions.formFailure('Error contacting server.'));

            dispatch(submitLoginFormSuccess());

            const { IsSplashNeeded, SplashContent, RedirectUrl, Token } = res.data;
            const jumpToUrl = getState().$$dataStore.get('jumpToUrl');
            setCookie("access_token", Token);

            // If splash screen returned. Set splash screen content
            if (IsSplashNeeded && SplashContent) {
                dispatch(setRedirectUrl(jumpToUrl || RedirectUrl));
                dispatch(setSplash(SplashContent));
                dispatch(appActions.changePage('splash'));
            }
            // Otherwise, redirect.
            else {
                window.location.href = jumpToUrl || RedirectUrl;
            }
        }
        catch (err) {
            const error = err.response;
            if(!error || !error.data) return dispatch(appActions.formFailure('Error contacting servers.'));

            const { AccountStatus, AreHintsNeeded, Errors, Token } = error.data;

            // Expired Password or First Time User
            if (AccountStatus === 'EXPIRED_PASSWORD') {
                dispatch(appActions.setHintsFlag(AreHintsNeeded));

                dispatch(submitLoginSuccessWithExpiredPassword(data.username, Token));
                dispatch(appActions.changePage('resetPassword'));
                dispatch(appActions.setErrors(getErrors(error.data)));
            }

            // Expired or Locked Account
            else if (AccountStatus === 'EXPIRED_ACCOUNT' || AccountStatus === 'LOCKED') {
                dispatch(submitLoginSuccessWithExpiredOrLockedAccount(data.username));
                dispatch(appActions.changePage('lockedAccount'));
                dispatch(appActions.setErrors(getErrors(error.data)));
            }

            // SSO User
            else if (AccountStatus === 'SSO_ACCOUNT' && getState().$$dataStore.get('defaultSsoUrl')) {
                return (window.location.href = getState().$$dataStore.get('defaultSsoUrl'));
            }

            // General failure response
            else {
                dispatch(appActions.formFailure(getErrors(error.data)));
            }
        }
    }
}