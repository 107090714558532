import React from "react";

import BasicPage from "../Layouts/BasicPage";

import Button from "../Components/Button";
import TextBoxWithIcon from "../Components/Inputs/TextBoxWithIcon";
import PasswordWithIcon from "../Components/Inputs/PasswordWithIcon";
import NavLink from "../Components/NavLink";
import { environmentLevelLists } from "../constants/constants";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: props.userData.get("username") || null,
      password: null,
      envKey: ""
    };

    this.handleDomainInput = this.handleDomainInput.bind(this);
    this.handleUsernameInput = this.handleUsernameInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSsoBtnClick = this.handleSsoBtnClick.bind(this);
    var that = this;

        fetch('config.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                 that.setState({ envKey: myJson.environmentvalue });
            });
  }

  handleDomainInput(e) {
    this.setState({ domain: e.target.value });
  }
  handleUsernameInput(e) {
    this.setState({ username: e.target.value });
  }
  handlePasswordInput(e) {
    this.setState({ password: e.target.value });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const { userData } = this.props;
    // Toggle Removal SP-45939: Remove the next line and all references to qParams from this component
    let toggle_sp45939 = userData.get('toggle').toJS()["SP_45939"];

    let qParams = {};
    window.location.search
      .substring(1)
      .split('&')
      .forEach((pair) => {
        pair = pair.split('=');
        qParams[pair[0]] = decodeURIComponent(pair[1] || '');
      });

    let data = {
      domain: this.state.domain,
      username: this.state.username,
      password: this.state.password,
      ...(!toggle_sp45939 &&
        qParams.apimLogin && { apimLogin: qParams.apimLogin === "1" }), // REMOVE ME WITH TOGGLE SP-45939
      ...(!toggle_sp45939 && qParams.env && { env: qParams.env }), // REMOVE ME WITH TOGGLE SP-45939
      ...(!toggle_sp45939 &&
        qParams.delegation && { delegation: qParams.delegation }), // REMOVE ME WITH TOGGLE SP-45939
    };
    if (this.state.ssoLogin) data.ssoLogin = this.state.ssoLogin;
    const { actions } = this.props;
    actions.submitLoginForm(data);
  }

  renderDomain() {
    const { userData } = this.props;

    if (!userData.get('domain') || userData.get('domain') === "") {
      return (
        <TextBoxWithIcon
          id="domain"
          name="domain"
          iconClass="fa fa-list-alt"
          placeholder="Domain"
          className="aur-text-box"
          defaultValue={this.state.domain}
          onChange={this.handleDomainInput}
        />
      );
    }
  }

  handleSsoBtnClick(e) {
    e.preventDefault();
    this.setState({ ssoLogin: true }, () => this.handleFormSubmit(e));
  }

  renderSsoBtn() {
    const { userData, appData } = this.props;

    if (userData.get('ssoBtnVisible')) {
      return (
        <div className="sso-btn-container">
          <hr className="sso-separator" />
          <Button
            type='button'
            disabled={appData.get('isProcessing')}
            className="aur-button secondary"
            onClick={this.handleSsoBtnClick}
          >
            SSO Sign-In
          </Button>
        </div>
      )
    }
  }

  render() {
    const { userData, appData, actions } = this.props;
    let {envKey} = this.state;
    let env = environmentLevelLists.find(a => a.key == envKey);
    let data = "";
    if(env) data = env.value;
    data = data==""? "Siterra" : data;
    let toggle_sp49484 = userData.get('toggle').toJS()["SP_49484"];

    return (
      <BasicPage {...{ appData, userData, actions }}>
        <form name='loginForm' onSubmit={this.handleFormSubmit}>
          {toggle_sp49484 && 
          <div
            disabled={appData.get('isProcessing')}
            className="aur-text primary"
          >
            {data}
          </div>}
          {this.renderDomain()}
          <TextBoxWithIcon
            id="username"
            iconClass="fa fa-user"
            placeholder="Username"
            className="aur-text-box"
            defaultValue={this.state.username}
            onChange={this.handleUsernameInput}
          />
          <PasswordWithIcon
            id="password"
            iconClass="fa fa-lock"
            placeholder="Password"
            className="aur-text-box"
            defaultValue={this.state.password}
            onChange={this.handlePasswordInput}
          />
          <Button
            type='submit'
            name="btnlogin"
            disabled={appData.get('isProcessing')}
            className="aur-button primary"
          >
            Sign In
          </Button>
          {this.renderSsoBtn()}
        </form>

        <ul id="nav_links">
          <li>
            <NavLink
              changePage={actions.changePage}
              to='forgotPassword'
              className="aur-link"
            >
              Forgot Password/Username
            </NavLink>
          </li>
          <li>
            <NavLink
              changePage={actions.changePage}
              to='lockedAccount'
              className="aur-link"
            >
              Locked/Expired Account
            </NavLink>
          </li>
          <li>
            <NavLink
              changePage={actions.changePage}
              to="accountApplication"
              className="aur-link"
            >
              Apply for Account
            </NavLink>
          </li>
        </ul>
      </BasicPage>
    );
  }
}

export default Login;