import React from "react";
import PropTypes from "prop-types";

const Password = props => {
    return (
        <input 
            type="password"
            id={props.id}
            name={props.name}
            className={props.className}
            disabled={props.disabled}
            placeholder={props.placeholder}
            inputMode={props.inputMode}
            required={props.required}
            autoComplete={props.autoComplete}
            minLength={props.minLength}
            maxLength={props.maxLength}
            pattern={props.pattern}
            title={props.title}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
        />
    );
};


Password.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    pattern: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    className: PropTypes.string,
    required: PropTypes.bool
};

export default Password;