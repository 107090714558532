import React from "react";
import PropTypes from "prop-types";

import IconSpan from "../Components/IconSpan";

const ErrorMessage = props => (
    <div className="login_error_div">
        <IconSpan iconClass="fa fa-exclamation-triangle" />
        {props.message}
    </div>
);

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired
}

export default ErrorMessage;
