import * as siterraActions from "./siterra/siterra.actions";
import siterraConfig from "./siterra/siterra.config.json";
import siterraReducer, { $$initialState as siterraInitialState } from "./siterra/siterraUserReducer";
import { images as siterraImages } from "./siterra/images";

import * as testActions from "./test/test.actions";
import testConfig from "./test/test.config.json";
import testReducer, { $$initialState as testInitialState } from "./test/testUserReducer";
import { images as testImages } from "./test/images";

import * as siterraConstants from './siterra/constants';

export const bundles = {
    "siterra": {
      actions: siterraActions,
      config: siterraConfig,
      reducer: siterraReducer,
      images: siterraImages,
      $$initialState: siterraInitialState
    },
    "test": {
      actions: testActions,
      config: testConfig,
      reducer: testReducer,
      images: testImages,
      $$initialState: testInitialState
    }
}

export const constants = {
  siterra: siterraConstants
}