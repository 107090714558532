import * as $ from '../../constants/constants';
import * as siterraActions from "./constants";
import { RequestManager } from "./dummyApi";
import { fetchToken } from "./testUserReducer";
import * as $$ from "../../stores/appActions";
import { urls } from "./test.config.json";

const validateFields = (fieldList, state) => {
    return fieldList.filter(x => (!state[x] || state[x] === ""))
        .map(x => {
            let fieldName = x.split("_").map(x => `${x.slice(0, 1).toUpperCase()}${x.slice(1, x.length).toLowerCase()}`).join(" ");
            return `${fieldName} cannot be empty.`;
        })
}

export function domainPageLoad() {
    // Parse url from window and see what happens
    let url = window.location.host;
    let potentialDomain = url.split('.')[0];
    if (potentialDomain == 'www') return;

    const uri = `/domains?name=${potentialDomain}`;

    return dispatch => {
        dispatch($$.setIsLoadingInitialState(true))

        RequestManager.getEntity(uri)
            .then(res => {
                dispatch(requestDomainSuccess(res.data.Name, res.data.DomainId));
                if (res.data.ImagePrefix) dispatch(setDomainLogo(res.data.ImagePrefix));
                dispatch($$.changePage('login'));
            })
            .catch(err => {
                dispatch($$.changePage("domain"));
            });
    }
}

export function requestDomainSuccess(domain, domainId) {
    return {
        type: siterraActions.REQUEST_DOMAIN_SUCCESS,
        domain,
        domainId,
    }
}

export function setDomainLogo(imagePrefix) {
    const imagePath = `./Images/${imagePrefix}-logo.gif`;
    return {
        type: siterraActions.SET_DOMAIN_LOGO,
        logo: imagePath
    }
}

export function setCountryOptions(countryOptions) {
    return {
        type: siterraActions.SET_COUNTRY_OPTIONS,
        countryOptions
    }
}

export function setStateOptions(stateOptions) {
    return {
        type: siterraActions.SET_STATE_OPTIONS,
        stateOptions
    }
}

export function setSplash(splash) {
    return {
        type: siterraActions.SET_SPLASH,
        splash
    }
}

export function setRedirectUrl(redirectUrl) {
    return {
        type: siterraActions.SET_REDIRECT_URL,
        redirectUrl
    }
}

function getErrors(errors) {
    return errors.map(x => x.ErrorMessage);
}



export function submitDomainForm(domainName) {
    const uri = `/domains?name=${domainName}`;

    if(!domainName || domainName === "") return dispatch => dispatch($$.formFailure(["Domain cannot be empty."]));

    return dispatch => {
        RequestManager.getEntity(uri)
            .then(res => {
                dispatch(requestDomainSuccess(domainName, res.data.DomainId));
                if (res.data.ImagePrefix) dispatch(setDomainLogo(res.data.ImagePrefix));

                dispatch($$.changePage('login'));
            });
    }
}

export function submitDomainFormSuccess(domain, domainId) {
    return {
        type: $.SUBMIT_DOMAIN_FORM_SUCCESS,
        domain,
        domainId
    }
}

export function submitLoginForm(data) {
    return (dispatch, getState) => {
        let errs = validateFields(["username", "password"].concat(!getState().$$dataStore.get('domain') ? ["domain"] : []), data);

        if(errs.length > 0) {
            return dispatch($$.formFailure(errs));
        }

        let postData = {
            "Username": data.username,
            "Password": data.password,
            "DomainName": data.domain
        };

        RequestManager.postEntity("/session", postData)
            .then(res => {
                dispatch(submitLoginFormSuccess());
                let { IsSplashNeeded, SplashContent, RedirectUrl } = res.data;
                if(IsSplashNeeded && SplashContent) {
                    dispatch(setRedirectUrl(RedirectUrl));
                    dispatch(setSplash(SplashContent));
                    dispatch($$.changePage("splash"));
                } else {
                    // Redirect
                    //window.location.href = res.data.RedirectUrl;
                    alert("Valid Credentials.");
                }
            })
            .catch(err => {
                let error = err.response;
                if(!error) return dispatch($$.formFailure('Error contacting servers.'));

                let { AccountStatus, AreHintsNeeded, Errors, Token } = error.data;
                // Expired Password or First Time User
                if (AccountStatus === 'EXPIRED_PASSWORD') {
                    if (AreHintsNeeded) dispatch($$.setHintsFlag(true));

                    dispatch(submitLoginSuccessWithExpiredPassword(data.username, Token));
                    dispatch($$.changePage('resetPassword'));
                    dispatch($$.setErrors(getErrors(Errors)));
                }

                // Expired or Locked Account
                else if (AccountStatus === 'EXPIRED_ACCOUNT' || AccountStatus === 'LOCKED') {
                    dispatch(submitLoginSuccessWithExpiredOrLockedAccount(data.username));
                    dispatch($$.changePage('lockedAccount'));
                    dispatch($$.setErrors(getErrors(Errors)));
                }

                // General failure response
                else {
                    dispatch($$.formFailure(getErrors(Errors)));
                }
            });
    }

}

export function submitLoginFormSuccess(data, page) {
    return {
        type: $.SUBMIT_LOGIN_FORM_SUCCESS,
        data
    }
}

export function submitLoginSuccessWithExpiredPassword (username, token) {
    return {
        type: $.SUBMIT_LOGIN_SUCCESS_EXPIRED_PASSWORD,
        username,
        token
    }
}

export function submitLoginSuccessWithExpiredOrLockedAccount (username) {
    return {
        type: $.SUBMIT_LOGIN_SUCCESS_EXPIRED_OR_LOCKED_ACCOUNT,
        username
    }
}

export function submitForgotPasswordForm(data) {
    let errs = validateFields(["lastName", "email"], data);
    if(errs.length > 0) return dispatch => dispatch($$.formFailure(errs));
    return dispatch => {
        let postData = { DomainId: data.domainId, LastName: data.lastName, EmailAddress: data.email };
        RequestManager.postEntity("/forgotpassword", postData)
            .then(res => {
                let { SecurityQuestionText, SuccessMessage } = res.data;
                dispatch(submitForgotPasswordFormSuccess(data.lastName, data.email, SecurityQuestionText));
                dispatch($$.changePage("forgotPasswordStep2"));
                dispatch($$.setInfo([SuccessMessage]));
            })
            .catch(err => {
                let error = err.response;
                if (!error) return dispatch($$.formFailure('Error contacting server.'));

                dispatch($$.formFailure(getErrors(err.response.data.Errors)));
            });
    }
}

export function submitForgotPasswordFormSuccess(lastName, email, hintQuestion) {
    return {
        type: $.SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS,
        lastName,
        email,
        hintQuestion
    }
}

export function submitForgotPasswordStep2Form(data) {
    const url = `/users/ResetPassword`;
    if(!data.hintAnswer || data.hintAnswer === "") return dispatch => dispatch($$.formFailure("Hint Answer cannot be empty."));

    return (dispatch, getState) => {
        let mappedData = {
            "DomainId": getState().$$dataStore.get('domainId'),
            "LastName": getState().$$dataStore.get('lastName'),
            "EmailAddress": getState().$$dataStore.get('email'),
            "SecurityAnswer": data.hintAnswer,
            "LoginUrl": window.location.href
        };
        RequestManager.postEntity(url, mappedData)
            .then(res => {
                let { SuccessMessage } = res.data;
                dispatch($$.changePage("blank"));
                dispatch($$.setInfo(SuccessMessage));
            })
            .catch(err => {
                if(!err.response) return dispatch($$.formFailure("Error contacting server."));

                dispatch($$.formFailure(getErrors(err.response.data.Errors)));
            });
    }
}

export function submitLockedAccountForm(data) {
    return (dispatch, getState) => {
        const url = `/unlockrequest`;
        let fieldsToValidate = [];

        if (!getState().$$dataStore.get("username")) fieldsToValidate = ["username"];

        let errs = validateFields(fieldsToValidate, data);
        if (errs.length > 0) {
            return dispatch($$.formFailure(errs));
        }

        RequestManager.postEntity(url, data)
            .then(res => {
                dispatch($$.changePage('blank'));
                dispatch($$.setInfo(res.data.SuccessMessage));
            })
            .catch(err => {
                let error = err.response;
                if (!error) return dispatch($$.formFailure('Error contacting server.'));

                if (!error.data.IsSuccess) {
                    dispatch($$.formFailure(getErrors(err.response.data.Errors)));
                }
            });
    }

}

export function submitResetPasswordForm(data) {
    return (dispatch, getState) => {
        const url = `/users/Security`;
        console.log(getState());
        let hintsRequired = getState().$$appStore.get('showHints');

        let fieldsToValidate = ["password", "confirmPassword"].concat(hintsRequired ? ["hintQuestion", "hintAnswer"] : []);
        let errs = validateFields(fieldsToValidate, data);
        if(data.password !== data.confirmPassword) errs.push("Password and Confirm Password must match.");
        if(errs.length > 0) return dispatch($$.formFailure(errs));

        let mappedData = {
            UserName: getState().$$dataStore.get('username'),
            DomainId: getState().$$dataStore.get('domainId'),
            NewPassword1: data.password,
            NewPassword2: data.confirmPassword
        };
        if(hintsRequired) {
            mappedData.PasswordHintQuestion = data.hintQuestion;
            mappedData.PasswordHintAnswer = data.hintAnswer;
        }

        RequestManager.postEntity(url, mappedData)
            .then(res => {
                dispatch($$.changePage('login'));
                dispatch($$.setInfo(res.data.SuccessMessage));
            })
            .catch(err => {
                let error = err.response;
                if (!error) return dispatch($$.formFailure('Error contacting server.'));

                dispatch($$.formFailure(getErrors(err.data.Errors)));
            });
    }
}


export function accountApplicationPageLoad() {
    return (dispatch, getState) => {
        let domainId = getState().$$dataStore.get('domainId');
        const url = `/countries?domainId=${domainId}`;
        RequestManager.getEntity(url)
            .then(res => {
                dispatch(setCountryOptions(res.data.Locations.map(x => { return {value: x.Id, label: x.Value} })));
            });
    }
}


export function accountApplicationCountryInput(country) {
    return (dispatch, getState) => {
        let domainId = getState().$$dataStore.get('domainId');
        const url = `/countries/${country.value}/states?domainId=${domainId}`;
        RequestManager.getEntity(url)
            .then(res => {
                dispatch(setStateOptions(res.data.Locations.map(x => { return {value: x.Id, label: x.Value} })));
            });
    }

}


export function submitAccountApplicationForm(data) {
    return (dispatch, getState) => {
        const url = `/accountrequests`;

        let fieldsToValidate = ["firstName", "lastName", "email", "phone", "address1", "city", "country", "state"];
        let errs = validateFields(fieldsToValidate, data);
        if(errs.length > 0) return dispatch($$.formFailure(errs));

        let mappedData = {
            "DomainId": getState().$$dataStore.get('domainId'),
            "FirstName": data.firstName,
            "LastName": data.lastName,
            "Title": data.title,
            "Company": data.company,
            "EmailAddress": data.email,
            "PhoneNumber": data.phone,
            "CountryCode": data.country,
            "Address1": data.address1,
            "Address2": data.address2,
            "City": data.city,
            "StateCode": data.state,
            "ZipCode": data.zipCode
        };

        RequestManager.postEntity(url, mappedData)
            .then(res => {
                if(res.data && res.data.SuccessMessage) {
                    dispatch($$.changePage("blank"));
                    dispatch($$.setInfo(res.data.SuccessMessage));
                }
            })
            .catch(err => {
                if(!err.response) return dispatch($$.formFailure("Unable to contact server."));

                dispatch($$.formFailure(getErrors(err.response.data.Errors)));
            });
    }
}
