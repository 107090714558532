import * as $ from "../constants/constants";
import bundle from "../config";

// These actions are loaded into the onSubmit methods for the forms
// in each page of the application.  Each of these actions first 
// dispatches an event which sets isProcessing to true and contains 
// the form data in the 'data' field.  It then checks for functions 
// of the same name within the custom actions defined for the active
// module and, if found, dispatches that function using the form data
// as the first (and only) parameter.
export default {
    requestDomain: function(data) {
        // Dispatches {type: "SUBMIT_DOMAIN_FORM", data: { domain: "theDomainInputValue" }}
        // Sets isProcessing to true, product can choose to handle data from action in reducer
        // Checks for action named requestDomain in product bundle and executes if found
        return function(dispatch) {
            dispatch({type: $.SUBMIT_DOMAIN_FORM, data});
            if(bundle.actions.requestDomain) {
                dispatch(bundle.actions.requestDomain(data));
            }
        }
    },
    submitForgotPasswordForm: function(data) {
        return function(dispatch) {
            dispatch({type: $.SUBMIT_FORGOT_PASSWORD_FORM, data});
            if(bundle.actions.submitForgotPasswordForm) {
                dispatch(bundle.actions.submitForgotPasswordForm(data));
            }
        }
    },
    submitForgotPasswordStep2Form: function(data) {
        return function(dispatch) {
            dispatch({type: $.SUBMIT_FORGOT_PASSWORD_STEP_2_FORM, data});
            if(bundle.actions.submitForgotPasswordStep2Form) {
                dispatch(bundle.actions.submitForgotPasswordStep2Form(data));
            }
        }
    },
    submitLockedAccountForm: function(data) {
        return function(dispatch) {
            dispatch({type: $.SUBMIT_LOCKED_ACCOUNT_FORM, data});
            if(bundle.actions.submitLockedAccountForm) {
                dispatch(bundle.actions.submitLockedAccountForm(data));
            }
        }
    },
    submitLoginForm: function(data) {
        return function(dispatch) {
            dispatch({type: $.SUBMIT_LOGIN_FORM, data});
            if(bundle.actions.submitLoginForm) {
                dispatch(bundle.actions.submitLoginForm(data));
            }
        }
    },
    submitResetPasswordForm: function(data) {
        return function(dispatch) {
            dispatch({type: $.SUBMIT_RESET_PASSWORD_FORM, data});
            if(bundle.actions.submitResetPasswordForm) {
                dispatch(bundle.actions.submitResetPasswordForm(data));
            }
        }
    },
    submitAccountApplicationForm: function(data) {
        return function(dispatch) {
            dispatch({type: $.SUBMIT_ACCOUNT_APPLICATION_FORM, data});
            if(bundle.actions.submitAccountApplicationForm) {
                dispatch(bundle.actions.submitAccountApplicationForm(data));
            }
        }
    },
    accountApplicationPageLoad: function() {
        return function(dispatch) {
            if(bundle.actions.accountApplicationPageLoad) {
                dispatch(bundle.actions.accountApplicationPageLoad());
            }
        }
    },
    domainPageLoad: function() {
        return function(dispatch) {
            if(bundle.actions.domainPageLoad) {
                dispatch(bundle.actions.domainPageLoad());
            }
        }
    },
    accountApplicationCountryInput: function(country) {
        return function(dispatch) {
            if(bundle.actions.accountApplicationCountryInput) {
                dispatch(bundle.actions.accountApplicationCountryInput(country));
            }
        }
    }
}