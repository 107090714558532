import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Pages
import DomainOnly from "./Pages/DomainOnly";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ForgotPasswordStep2 from "./Pages/ForgotPasswordStep2";
import Blank from "./Pages/Blank";
import LockedAccount from "./Pages/LockedAccount";
import AccountApplication from "./Pages/AccountApplication";
import ResetPassword from "./Pages/ResetPassword";
import Splash from "./Pages/Splash";

import bundle from "./config";
import { images } from "./images";
import formActions from "./stores/formActions";
import * as appActions from "./stores/appActions";

import LayoutProvider from "./Layouts/LayoutProvider";

/*
*  Here is where we can differentiate between different product and app flows.
*  right now we are using the $$siterraUserStore to pass state down the tree.
*  If we need to jump over to a '360' flow. We can use the same layout provider
*  and pass down state.$$360userStore as the userData prop. 
*
*  This does mean we will have pages that look similar but this duplication
*  allows us to maintain deterministic redux principles. Some basic pages could
*  still be reused by this.getPage()
*/
class PageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.getPage = this.getPage.bind(this);
    this.getCurrentModeActions = this.getCurrentModeActions.bind(this);
  }

  getPage() {
    const { appData, userData } = this.props;
    let actions = this.getCurrentModeActions();

    switch (appData.get('page')) {

      case 'initial': {
        if (appData.get('isLoadingInitialState')) {
            // do nothing so that we don't trigger double actions
        }
        else {
            actions.domainPageLoad();
        }
        break;
      }

      case 'accountApplication':
        // Logo must be provided directly since this page does not use the BasicPage layout
        return <AccountApplication {...{ appData, userData, actions, logo: bundle.images[bundle.config.logo] }} />

      case 'domain':
        return <DomainOnly {...{ appData, userData, actions }} />;

      case 'login':
        return <Login {...{ appData, userData, actions }} />;

      case 'forgotPassword':
        return <ForgotPassword {...{ appData, userData, actions }} />;

      case 'forgotPasswordStep2':
        return <ForgotPasswordStep2 {...{ appData, userData, actions }} />;

      case 'lockedAccount':
        return <LockedAccount {...{ appData, userData, actions }} />;

      case 'resetPassword':
        return <ResetPassword {...{ appData, userData, actions }} />;
      
      case 'splash':
        return <Splash {...{ appData, userData, actions }} imageRepo={{...images, ...bundle.images}} />;

      case 'blank':
        return <Blank {...{ appData, userData, actions }} />

      default:
        return <p>default page heh.</p>
    }
  }

  // This function provides basic navigation from appActions and
  // the wrapper functions from formActions which are bound to
  // the form found on each page in the application.
  getCurrentModeActions() {
    const dispatch = this.props.dispatch;
    return bindActionCreators({...appActions, ...formActions}, dispatch);
  }

  render() {
    const { layout, appData } = this.props;

    return (
      // TODO: uncouple layouts from react app
      // imageRepo contains images found in the root '/images' folder
      // as well as the images for the active module found in the
      // '/bundles' folder
      <LayoutProvider layout={layout} {...{ appData }} message={{body: appData.get('messageBody'), title: appData.get('messageTitle')}} imageRepo={{...images, ...bundle.images}}>
          {this.getPage()}
      </LayoutProvider>
    )
  }
}

// Maps all store contents to props.appData.
// Not sure if we really need to mask this.
const mapStateToProps = (state) => {
  let layout = "offset";
  if(state.$$appStore.get('page') === 'accountApplication') layout = "middle";
  if(state.$$appStore.get('page') === 'splash') layout = "splash";
  return {
    appData: state.$$appStore,
    userData: state.$$dataStore,
    layout
  }
};

export default connect(mapStateToProps/*, mapDispatchToProps*/)(PageContainer);