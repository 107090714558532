import "babel-polyfill";
import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import './css/index.css';
import registerServiceWorker from './registerServiceWorker';

import './css/aurora.css';
import './css/App.css';
import './css/font-awesome.min.css';

import PageContainer from "./PageContainer";
import configureStore from './stores/store';

const target = document.querySelector('#root');
const store = configureStore();

render(
    <Provider store={store}>
        <PageContainer />
    </Provider>
    , target);

/// SP-33382: Removed to prevent Chrome from sending SSO users back to this React applications after login
/// Uncomment the line below to enable service worker caching.

//registerServiceWorker();
