import * as actionTypes from "../constants/constants";

export const setInfo = info => {
    return {
        type: actionTypes.INFO,
        info
    }
}

export const setErrors = errors => {
    return {
        type: actionTypes.ERROR,
        errors
    } 
}

export const setIsLoadingInitialState = isLoadingInitialState => {
    return {
        type: actionTypes.SET_IS_LOADING_INITIAL_STATE,
        isLoadingInitialState
    }
}

export const formFailure = errors => {
    return {
        type: actionTypes.FORM_FAILURE,
        errors
    }
}

export const setHintsFlag = showHints => {
    return {
        type: actionTypes.SET_SHOW_HINTS,
        showHints
    }
}

export const setIsProcessing = isProcessing => {
    return {
        type: actionTypes.SET_IS_PROCESSING,
        isProcessing
    }
}

export const changePage = page => {
    return {
        type: actionTypes.CHANGE_PAGE,
        page
    }
}

export const setMessage = message => {
    return {
        type: actionTypes.SET_MESSAGE,
        message
    }
}

export const setLogo = logo => {
    return {
        type: actionTypes.SET_LOGO,
        logo
    }
}
