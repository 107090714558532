import Immutable from 'immutable';
import * as actionTypes from './constants';


export const $$initialState = Immutable.fromJS({
   username: null,
   domain: null,
   domainId: null,
   logo: null,
   password: null,
   token: null,
   accountStatus: null,
   stateOptions: [],
   countryOptions: [],
   splash: null,
   redirectUrl: null,
   hintQuestion: null,
   lastName: null,
   email: null,
   ssoBtnVisible: false,
   ssoAuthUrl: null,
   defaultSsoUrl: null,
   jumpToUrl: null,
   errorFields: [],
   toggles: null,
});


/* ------------------------------------------- 
* We can have as many of these reducers as we 
* need to handle different product information.
* -------------------------------------------- */
export default function siterraUserReducer($$state = $$initialState, action = null) {
    const { type, domain, domainId, username, toggle, token, countryOptions, stateOptions, splash, redirectUrl, hintQuestion, lastName, email, logo, ssoBtnVisible, ssoAuthUrl, defaultSsoUrl, jumpToUrl, errorFields } = action;

    switch(type) {
        case actionTypes.REQUEST_DOMAIN_SUCCESS:
            return $$state.merge({
                domain,
                domainId
            });

        case actionTypes.SUBMIT_DOMAIN_FORM_FAILURE:
            return $$state.merge({
                domain: null,
                domainId: null
            });


        case actionTypes.SET_TOGGLES:
            return $$state.merge({
                toggle: toggle
            });

        case actionTypes.SUBMIT_LOGIN_FORM_SUCCESS:
            return $$state.merge({
                token: token
            });

        case actionTypes.SUBMIT_LOGIN_SUCCESS_EXPIRED_OR_LOCKED_ACCOUNT:
            return $$state.merge({
                username
            })

        case actionTypes.SUBMIT_LOGIN_SUCCESS_EXPIRED_PASSWORD:
            return $$state.merge({
                username,
                token
            });
        
        case actionTypes.SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS:
            return $$state.merge({
                lastName,
                email,
                hintQuestion
            });

        case actionTypes.SET_COUNTRY_OPTIONS:
            return $$state.merge({
                countryOptions
            });
            
        case actionTypes.SET_STATE_OPTIONS:
            return $$state.merge({
                stateOptions
            });
        
        case actionTypes.SET_SPLASH:
            return $$state.merge({
                splash
            })
        
        case actionTypes.SET_REDIRECT_URL:
            return $$state.merge({
                redirectUrl
            });

        case actionTypes.SET_DOMAIN_LOGO:
            return $$state.merge({
                logo
            });
        
        case actionTypes.SET_SSO_LOGIN:
            return $$state.merge({
                ssoBtnVisible, 
                ssoAuthUrl, 
                defaultSsoUrl
            });

        case actionTypes.SET_JUMPTO_URL:
            return $$state.merge({
                jumpToUrl
            });
        
        case actionTypes.SET_ERROR_FIELDS:
            return $$state.merge({
                errorFields: Array.isArray(errorFields) ? errorFields : [errorFields]
            })

        default:
            return $$state;
    }
}