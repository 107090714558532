import bundle from "../config";
import appReducer, { getInitialState as getAppInitialState } from './appReducer';

export default class {
  // Custom Reducer
  static get $$dataStore() { return bundle.reducer }
  
  // Core Reducer
  static get $$appStore() { return appReducer }
}

export const initialStates = class {
  // Custom Initial State
  static get $$dataState() { return bundle.$$initialState }

  // Initial state based on product config (logo, backgrounds, front message)
  static get $$appState() { return getAppInitialState() }
}

