import React from "react";
import PropTypes from "prop-types";

const IconSpan = props => {
    let iClass = props.iconClass;
    if(props.className) iClass += ` ${props.className}`;
    return (
        <span
            id={props.id}
            className={iClass}
            aria-hidden="true"
            onClick={props.onClick}
        >
            {props.children}
        </span>
    );
};

IconSpan.propTypes = {
    id: PropTypes.string,
    iconClass: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default IconSpan;