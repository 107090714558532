import React from "react";

import Button from "../Components/Button";
import TextBoxWithIcon from "../Components/Inputs/TextBoxWithIcon";
import PasswordWithIcon from "../Components/Inputs/PasswordWithIcon";
import NavLink from "../Components/NavLink";

import BasicPage from "../Layouts/BasicPage";

class LockedAccount extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            username: this.props.userData.get('username') || null,
            password: null,
            toggle: this.props.userData.get('toggle').toJS()
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleUserInput(e){ this.setState({username: e.target.value}) }

    handleFormSubmit(e){
        e.preventDefault();
        let data = {
            username: this.state.username,
        }
        this.props.actions.submitLockedAccountForm(data);
    }

    renderUsername() {
        const { userData } = this.props;

        if(!userData.get('token') && !userData.get('username')) return (
            <TextBoxWithIcon
                id="username"
                iconClass="fa fa-user"
                placeholder="Username"
                className="aur-text-box"
                defaultValue={this.state.username}
                onChange={this.handleUserInput}
            />
        )
    }

    render() {
        const { appData, userData, actions } = this.props;

        return (
            <BasicPage title="Reset Locked/Expired Account" {...{ appData, userData, actions}}>
                <form onSubmit={this.handleFormSubmit}>
                    {this.renderUsername()}
                    <Button
                        type='submit'
                        disabled={appData.get('isProcessing')}
                        className="aur-button primary" >
                        Submit Account Unlock Request
                    </Button>
                </form>
                <ul id="nav_links">
                    <li><NavLink changePage={actions.changePage} to='login' className="aur-link">Return to Login</NavLink></li>
                </ul>
            </BasicPage>
        );
    }
}

export default LockedAccount;
