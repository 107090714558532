import React from "react";
import BasicPage from "../Layouts/BasicPage";
import Button from "../Components/Button";

import TextBoxWithIcon from "../Components/Inputs/TextBoxWithIcon";
import NavLink from "../Components/NavLink";

class ForgotPassword extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            lastName: null,
            email: null
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleLastNameInput = this.handleLastNameInput.bind(this);
        this.handleEmailInput = this.handleEmailInput.bind(this);
    }

    handleFormSubmit(e) {
        e.preventDefault();
        
        let data = {
            lastName: this.state.lastName,
            email: this.state.email
        }
        this.props.actions.submitForgotPasswordForm(data);
    }

    handleLastNameInput(e) { this.setState({ lastName: e.target.value }) }
    handleEmailInput(e) { this.setState({ email: e.target.value }) }

    render() {
        const { appData, userData, actions } = this.props;
        return (
            <BasicPage title="Request New Password" {...{ appData, userData, actions}}>
                <form onSubmit={this.handleFormSubmit}>
                    <TextBoxWithIcon 
                        id="last_name" 
                        iconClass="fa fa-user" 
                        placeholder="Last Name"
                        className="aur-text-box"
                        onChange={this.handleLastNameInput}
                    />
                    <TextBoxWithIcon 
                        id="email" 
                        iconClass="fa fa-envelope" 
                        placeholder="Email"
                        className="aur-text-box"
                        onChange={this.handleEmailInput}
                    />

                    <Button 
                        type='submit'
                        disabled={appData.get('isProcessing')} 
                        className="aur-button primary" >Submit
                    </Button>

                </form>

                <ul id="nav_links">
                    <li><NavLink changePage={actions.changePage} to='login' className="aur-link">Return to Login</NavLink></li>
                </ul>
            </BasicPage>
        )
    }
}

export default ForgotPassword;