import React from "react";
import PropTypes from 'prop-types';

const TextBox = props => {
    return (
        <input 
            type="text"
            id={props.id}
            disabled={props.disabled}
            placeholder={props.placeholder}
            value={props.value}
            defaultValue={props.defaultValue}
            onBlur={props.onBlur}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onInput={props.onInput}
            className={props.className}
            required={props.required}
            name={props.name}
        />
    );
};

TextBox.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    className: PropTypes.string,
    required: PropTypes.bool,
    name: PropTypes.string
};

export default TextBox;