import Immutable from 'immutable';
import * as actionTypes from '../constants/constants';
import bundle from "../config";

// Using function to load initial state dynamically based on custom config
export const getInitialState = () => {
    return Immutable.fromJS({
        page: 'initial',
        currentMode: bundle.activeModule,
        info: [],
        errors: [],
        isProcessing: false,
        isLoadingInitialState: false,
        showHints: false,
        messageTitle: bundle.config.frontMessage.title || null,
        messageBody: bundle.config.frontMessage.body || null,
        logo: bundle.config.logo || null, // Add Acrruent default logo instead?
        background: bundle.config.background || null, // Add Accruent default background instead?
        bgCycleTime: bundle.config.bgCycleTime || 10000
    });
}

/* ------------------------------------------- 
* Handles anything reused by the app 
* -------------------------------------------- */
export default function appReducer($$state = getInitialState(), action = null) {
    const { type, page, errors, info, isProcessing, message, logo, background, bgCycleTime, showHints, isLoadingInitialState } = action;

    switch(type) {
        case actionTypes.SET_IS_PROCESSING:
            return $$state.merge({
                isProcessing
            });


        case actionTypes.SET_IS_LOADING_INITIAL_STATE:
            return $$state.merge({
                isLoadingInitialState,
                isProcessing: true // to show spinner on initial load
            })

        case actionTypes.SET_SHOW_HINTS:
            return $$state.merge({
                showHints
            });

        case actionTypes.CHANGE_PAGE:
            return $$state.merge({
                isProcessing: false,
                isLoadingInitialState: false,
                errors: [],
                info: [],
                page,
            });

        case actionTypes.ERROR:
            return $$state.merge({
                errors: Array.isArray(errors) ? errors : [errors]
            });
        
        case actionTypes.INFO:
            return $$state.merge({
                info: Array.isArray(info) ? info : [info]
            });

        case actionTypes.SET_MESSAGE:
            return $$state.merge({
                message
            });

        case actionTypes.SET_LOGO:
            return $$state.merge({
                logo
            });

        case actionTypes.SET_BACKGROUND:
            return $$state.merge({
                background
            });

        case actionTypes.SET_BG_CYCLE_TIME:
            return $$state.merge({
                bgCycleTime
            });

        case actionTypes.FORM_FAILURE:
            return $$state.merge({
                errors: Array.isArray(errors) ? errors : [errors],
                isProcessing: false
            });

       

        // All will only set isProcessing to false
        case actionTypes.SUBMIT_DOMAIN_FORM:
        case actionTypes.SUBMIT_LOGIN_FORM:
        case actionTypes.SUBMIT_RESET_PASSWORD_FORM:
        case actionTypes.SUBMIT_LOCKED_ACCOUNT_FORM:
        case actionTypes.SUBMIT_FORGOT_PASSWORD_FORM:
        case actionTypes.SUBMIT_FORGOT_PASSWORD_STEP_2_FORM:
        case actionTypes.SUBMIT_ACCOUNT_APPLICATION_FORM:
            return $$state.merge({
                isProcessing: true
            });

        default:
            return $$state;
    }
}