import React from "react";
import PropTypes from "prop-types";

const Label = props => (
    <label
        id={props.id}
        htmlFor={props.for}
        className={props.className}
    >
        {props.children}
    </label>
);

Label.propTypes = {
    id: PropTypes.string,
    htmlFor: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired
}

export default Label;