import React from "react";
import PropTypes from 'prop-types';

import Select from "react-select";
import "react-select/dist/react-select.css";

const Dropdown = props => {
    return (
        <Select {...props} />
    )
}

Dropdown.propTypes = {
  id: PropTypes.string,
  classname: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }))
}

export default Dropdown;