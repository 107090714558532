import request from 'axios';

import { tokenUrl } from "../package.json";

const BASE_URL = tokenUrl || 'apis/sit/admin/v1/tokens/getUISession';

export default {
  /**
   * Retrieve list of entities from server using AJAX call.
   *
   * @returns {Promise} - Result of ajax call.
   */
  getEntity(url) {
    return request({
      method: 'GET',
      url: url,
      responseType: 'json'
    });
  },

  postEntity(uri, data) {
    return request({
      method: 'POST',
      url: uri,
      responseType: 'json',
      data: data
    });
  },

  patchEntity(url, data) {
    return request({
      method: 'PATCH',
      responseType: 'json',
      url,
      data
    });
  },
}