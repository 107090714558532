import landscape from "./landscape.jpg";
import landscape2 from "./landscape2.jpg";
import landscape3 from "./landscape3.jpg";
import siterraLogo from "./Siterra-Logo.png";

export const images = {
    "landscape.jpg": landscape,
    "landscape2.jpg": landscape2,
    "landscape3.jpg": landscape3,
    "Siterra-Logo.png": siterraLogo
}