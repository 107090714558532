import React from "react";
import PropTypes from "prop-types";

import BgSlideShow from "./BgSlideShow";

const BgProvider = props => {
    const multipleImages = Array.isArray(props.background) ? true : false;

    if (multipleImages) return <BgSlideShow {...props} />
    else {
        return (
            <div className="application-bg">
                <img src={props.background} alt="background" />
            </div>
        )
    }
}

BgProvider.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string
    ]).isRequired,
    bgCycleTime: PropTypes.number.isRequired
}

export default BgProvider;