import Immutable from "immutable";

export class Reducer {
    constructor(schema, actionTypes) {
        this.schema = schema;
        this.actionTypes = actionTypes;

        // Setup the Immutable JS Store
        this.store = this.createStore(schema);
        this.createReducer = this.createReducer.bind(this);
        this.createStore = this.createStore.bind(this);
        this.handlers = [];
    }

    createStore() {
        return Immutable.fromJS(this.schema);
    }

    createReducer() {
        let self = this;
        return function reducer($$state = self.store, action=null) {
            // Ignore actionTypes not found in this.actionTypes
            if(self.actionTypes.indexOf(action.type) < 0) return $$state;
    
            // Check for any keys defined in our initial schema
            // and merge in all matching keys
            let newState = {...$$state.toObject()};
            let handlers = self.handlers.filter(x => Array.isArray(x.type) ? x.type.indexOf(action.type) >= 0 : x === action.type);
            if(handlers.length > 0) {
                // Found one or more handlers that override the basic merge action
                handlers.forEach(x => newState = {...newState, ...x.handler(newState, action)});
            } else {
                Object.keys(self.schema).forEach(schemaKey => {
                    if(action[schemaKey]) {
                        newState[schemaKey] = action[schemaKey];
                    }
                });
            }
            
            self.store = $$state.merge(newState);
            return self.store;
        }
    }

    addActionType(actionType) {
        this.actionTypes.push(actionType);
        return this;
    }

    addActionHandler(actionType, handler){
        this.handlers.push({type: actionType, handler});
        return this;
    }

    removeActionHandler(actionType, handler) {
        this.handlers = this.handlers.filter(x => x.type !== actionType && x.handler !== handler);
    }
}