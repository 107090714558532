import React from "react";
import Button from "../Components/Button";
import { Parser } from "html-to-react";

class Splash extends React.Component {
    constructor(props) {
        super(props);

        this.parser = new Parser();
        this.renderSplash = this.renderSplash.bind(this);
        this.handleSplashContinueClick = this.handleSplashContinueClick.bind(this);
    }

    renderSplash() {
        return this.parser.parse(this.props.userData.get('splash'));
    }

    handleSplashContinueClick() {
        this.props.actions.setIsProcessing(true);
        window.location.href = this.props.userData.get("redirectUrl");
    }

    render() {
        return (
            <div id="accountApplicationContainer">
                <div className="splashContainer">
                    {this.renderSplash()}
                    <Button
                        disabled={this.props.appData.get("isProcessing")}
                        type="button"
                        onClick={this.handleSplashContinueClick}
                        className={"aur-button primary"}
                    >Continue</Button>
                </div>
            </div>
        )
    }
}

export default Splash;