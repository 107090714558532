import React from "react";

import BasicPage from "../Layouts/BasicPage";
import NavLink from "../Components/NavLink";

const Blank = props => {
    const { appData, userData, actions } = props;
    return (
        <BasicPage {...{ appData, userData, actions}}>
            {props.children}
            <ul id="nav_links">
                <li><NavLink changePage={props.actions.changePage} to='login' className="aur-link">Return to Login</NavLink></li>
            </ul>
        </BasicPage>
    )
}

export default Blank;