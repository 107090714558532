import React from "react";
import BasicPage from "../Layouts/BasicPage";
import Button from "../Components/Button";
import Label from "../Components/Label";

import TextBoxWithIcon from "../Components/Inputs/TextBoxWithIcon";
import NavLink from "../Components/NavLink";

class ForgotPasswordStep2 extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            hintAnswer: null
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleHintAnswerInput = this.handleHintAnswerInput.bind(this);
    }

    handleFormSubmit(e) {
        e.preventDefault();
        
        let data = {
            hintAnswer: this.state.hintAnswer
        }
        this.props.actions.submitForgotPasswordStep2Form(data);
    }

    handleHintAnswerInput(e) { this.setState({ hintAnswer: e.target.value }) }
    
    render() {
        const { appData, userData, actions } = this.props;
        return (
            <BasicPage title="Request New Password" {...{ appData, userData, actions}}>
                <form onSubmit={this.handleFormSubmit}>
                    <div className={"hintQuestion"}>{this.props.userData.get("hintQuestion")}</div>
                    <TextBoxWithIcon 
                        id="hintAnswer" 
                        placeholder="Answer"
                        iconClass="fa fa-lock" 
                        className="aur-text-box"
                        onChange={this.handleHintAnswerInput}
                    />

                    <Button 
                        type='submit'
                        disabled={appData.get('isProcessing')} 
                        className="aur-button primary" >Submit
                    </Button>
                </form>

                <ul id="nav_links">
                    <li><NavLink changePage={actions.changePage} to='login' className="aur-link">Return to Login</NavLink></li>
                </ul>
            </BasicPage>
        )
    }
}

export default ForgotPasswordStep2;