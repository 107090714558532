import React from 'react';
import PropTypes from 'prop-types';

const NavLink = props => {
  return (
    <a className={props.className} onClick={() => props.changePage(props.to) } >{props.text || props.children}</a>
  )
}

NavLink.propTypes = {
  className: PropTypes.string,
  changePage: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired
}

export default NavLink;
