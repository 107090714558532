import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import reducers, { initialStates } from '../reducers';
import { changeModule } from "../config";

export default (props) => {
    // Returns generated initial appStore state and custom dataState
    const getInitialStates = () => {
        const { $$appStore, $$dataStore } = initialStates;
        return { $$appStore, $$dataStore }
    }

    // Returns combined app reducer and custom reducer from config
    const getReducers = () => {
        return combineReducers({ $$appStore: reducers.$$appStore, $$dataStore: reducers.$$dataStore });
    }

    // Allows store states to be reset and new custom reducer to be loaded
    const rootReducer = (state, action) => {
        if(action.type === "CHANGE_MODE") {
            if(action.module) changeModule(action.module);
            state = getInitialStates();
        }

        return getReducers()(state, action);
    }

    const store = createStore(rootReducer, getInitialStates(), applyMiddleware(thunk, logger));
    window.store = store;
    return store;
}
