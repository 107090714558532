import React from "react";
import Button from "../Components/Button";

import TextBoxWithIcon from "../Components/Inputs/TextBoxWithIcon";
import PasswordWithIcon from "../Components/Inputs/PasswordWithIcon";

import BasicPage from "../Layouts/BasicPage";

class ResetPassword extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            password: null,
            confirmPassword: null,
        };
        
        this.handlePasswordInput = this.handlePasswordInput.bind(this);
        this.handleConfirmPasswordInput = this.handleConfirmPasswordInput.bind(this);
        this.handleHintQuestionInput = this.handleHintQuestionInput.bind(this);
        this.handleHintAnswerInput = this.handleHintAnswerInput.bind(this);
        
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handlePasswordInput(e){this.setState({password: e.target.value})}
    handleConfirmPasswordInput(e){this.setState({confirmPassword: e.target.value})}
    handleHintQuestionInput(e){this.setState({hintQuestion: e.target.value})}
    handleHintAnswerInput(e){this.setState({hintAnswer: e.target.value})}

    handleFormSubmit(e){
        e.preventDefault();
        let data = {
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            hintQuestion: this.state.hintQuestion,
            hintAnswer: this.state.hintAnswer
        };
        this.props.actions.submitResetPasswordForm({...data});
    }

    renderHintQuestion() {
        if(this.props.appData.get("showHints")) {
            return (
                <TextBoxWithIcon 
                    id="hintQuestion"
                    iconClass="fa fa-list"
                    placeholder="Password Hint Question"
                    className="aur-text-box"
                    onChange={this.handleHintQuestionInput}
                />
            )
        }
    }

    renderHintAnswer() {
        if(this.props.appData.get("showHints")) {
            return (
                <TextBoxWithIcon 
                    id="hintAnswer"
                    iconClass="fa fa-list"
                    placeholder="Password Hint Answer"
                    className="aur-text-box"
                    onChange={this.handleHintAnswerInput}
                />
            )
        }
    }

    render() {
        const { appData, userData, actions } = this.props;

        return (
            <BasicPage title={"Submit New Password"} {...{ appData, userData, actions}}>
                <form onSubmit={this.handleFormSubmit}>
                    <PasswordWithIcon 
                        id="password" 
                        iconClass="fa fa-lock" 
                        placeholder="New Password" 
                        className="aur-text-box"
                        onChange={this.handlePasswordInput}
                    />
                    <PasswordWithIcon 
                        id="confirmPassword" 
                        iconClass="fa fa-lock" 
                        placeholder="Confirm Password" 
                        className="aur-text-box"
                        onChange={this.handleConfirmPasswordInput}
                    />
                    {this.renderHintQuestion()}
                    {this.renderHintAnswer()}

                    <Button 
                        type='submit'
                        disabled={appData.get('isProcessing')} 
                        className="aur-button primary" >Continue
                    </Button>
                </form>
            </BasicPage>
        );
    }
}

export default ResetPassword;