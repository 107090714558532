import siterraLogo from "./Siterra-Logo.png";
import bg1 from "./siterraBg1.jpg";
import bg2 from "./siterraBg2.jpg";
import bg3 from "./siterraBg3.jpg";
import bg4 from "./siterraBg4.jpg";
import bg5 from "./kevin-fitzgerald-479720.jpg";

export const images = {
    "Siterra-Logo.png": siterraLogo,
    "bg1": bg1,
    "bg2": bg2,
    "bg3": bg3,
    "bg4": bg4,
    "bg5": bg5
}