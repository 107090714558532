import { activeModule } from "./app.config.json";
import { bundles } from "../bundles";

// Actively manages custom configs to allow dynamic changing of
// the active module.
class Config {
    constructor(activeModule, bundles) {
        this._activeModule = activeModule;
        this.bundles = bundles;
    }

    get activeModule() {
        return this._activeModule;
    }

    get actions() {
        return this.bundles[this.activeModule].actions;
    }

    get config() {
        return this.bundles[this.activeModule].config;
    }

    get reducer() {
        return this.bundles[this.activeModule].reducer;
    }

    get images() {
        return this.bundles[this.activeModule].images;
    }

    get $$initialState() {
        return this.bundles[this.activeModule].$$initialState;
    }

    changeModule(newModule) {
        this._activeModule = newModule;
    }
}

const config = new Config(activeModule, bundles);
export default config;
export const changeModule = mod => {
    config.changeModule(mod);
}