import React from 'react';
import PropTypes from 'prop-types';

const Button = props => {
  return (
    <button
          type={props.type === 'submit' ? 'submit' : 'button'}
      className={props.className}
      onClick={props.onClick}
      disabled={props.disabled}
      name={props.name}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
};

export default Button;
