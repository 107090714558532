import * as actionTypes from '../../constants/constants';
import * as siterraActions from "./constants";
import { Reducer } from "../helpers";

const storeSchema = { 
    username: null,
    domain: null,
    domainId: null,
    logo: null,
    password: null,
    token: null,
    accountStatus: null,
    stateOptions: [],
    countryOptions: [],
    splash: null,
    redirectUrl: null,
    hintQuestion: null,
    lastName: null,
    email: null
};

const myActionTypes = Object.keys(actionTypes).concat(Object.keys(siterraActions));

const testReducer = new Reducer(storeSchema, myActionTypes);

export const $$initialState = testReducer.store;

export default testReducer.createReducer();

