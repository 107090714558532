import React from "react";

export const MiddlePanelLayout = (props) => {
    return (
        <div className="login-layout-middle">
            <div className="login-layout-middle-main">
                {props.children}
            </div>
        </div>
    );
};