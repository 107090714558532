import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { OffsetPanelLayout } from './OffsetPanelLayout';
import { MiddlePanelLayout } from './MiddlePanelLayout';
import { SplashLayout } from "./SplashLayout";
import BgProvider from "../Components/BgProvider";

import accLogo from "../images/AccruentLogo.png";

class LayoutProvider extends Component {
    render() {
        const { appData, imageRepo } = this.props;
        const layouts = { "middle": MiddlePanelLayout, "offset": OffsetPanelLayout, "splash": SplashLayout };
        //let layout = this.props.layout === "middle" ? MiddlePanelLayout : OffsetPanelLayout;
        let layout = layouts[this.props.layout];
        layout = layout({...this.props});

        let background;
        if (appData.get('background').toArray) {
            background = appData.get('background').toArray().map(x => imageRepo[x] || x);
        } else {
            background = imageRepo[appData.get('background')] || appData.get('background');
        }

        return (
        <div className="aur-application-wrapper">
            <BgProvider background={background} bgCycleTime={appData.get('bgCycleTime')} />
            {layout}
            <div className="application-footer">
                <div className="footer-logo">
                    <img src={accLogo} className="footer-logo-img" alt="Accruent Logo" />
                </div>
                <div className="footer-copyright">
                    &copy; Copyright {new Date().getFullYear()} Accruent All Rights Reserved
                </div>
                <div className="footer-privacy">
                    <a href="http://www.accruent.com/privacy-policy" className="footer-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </div>
                <div className="footer-browsers">
                    Compatible Browsers: Chrome
                </div>
            </div>
        </div>
        );
    }
}

LayoutProvider.propTypes = {
    appData: PropTypes.object.isRequired,
    layout: PropTypes.string.isRequired,
    imageRepo: PropTypes.object.isRequired
}

export default LayoutProvider;
