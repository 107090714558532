import React from "react";

class BgSlideShow extends React.Component {
    constructor(props) {
        super(props);

        this.backgrounds = this.props.background;
        let first_bg = this.backgrounds[ Math.floor(Math.random() * this.backgrounds.length) ];
        this.state = {
            background: first_bg,
            bgStyle: { opacity: 1 },
            nextBgStyle: { opacity: 0 },
            activeImage: "first",
            cycleTime: this.props.bgCycleTime
        };
        this.bgTimer = setInterval(
            this.cycleBgImage.bind(this), 
            this.state.cycleTime
        );
    }

    cycleBgImage() {
        let nextImage = this.getNextBgImage([this.state.background, this.state.nextBackground]);
        if(this.state.activeImage === "first") {
            this.setState(
                {nextBackground: nextImage, activeImage: "second"}, 
                ()=>{ setTimeout(() => { this.setState({nextBgStyle: { opacity: 1 }, bgStyle: { opacity: 0, }}) }, 500) }
            );
        }
        else {
            this.setState(
                {background: nextImage, activeImage: "first"},
                ()=>{ setTimeout(() => { this.setState({bgStyle: { opacity: 1 }, nextBgStyle: { opacity: 0 }}) }, 500) }
            );
        }
    }

    getNextBgImage(excludeImg) {
        let possibleCurrentImages = this.backgrounds.filter(x => Array.isArray(excludeImg) ? excludeImg.indexOf(x) === -1 : x !== excludeImg );
        return possibleCurrentImages[ Math.floor(Math.random() * possibleCurrentImages.length) ];
    }

    render() {
        return (
            <div className="application-bg">
                <img src={this.state.background} alt="background" style={this.state.bgStyle} />
                <img src={this.state.nextBackground} alt="background" style={this.state.nextBgStyle} />
            </div>
        )
        
    }
}

export default BgSlideShow;