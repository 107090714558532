import React from "react";
import PropTypes from 'prop-types';

import Password from "./Password";
import IconSpan from "../IconSpan";

const PasswordWithIcon = props => {
    return (
        <div className="input-icon-container">
            <IconSpan iconClass={`input-icon ${props.iconClass}`} ></IconSpan>
            <Password {...props} />
        </div>
    )
};

PasswordWithIcon.propTypes = {
  iconClass: PropTypes.string.isRequired
}

export default PasswordWithIcon;
