import React from "react";
import Button from "../Components/Button";
import Label from "../Components/Label";
import TextBox from "../Components/Inputs/TextBox";
import TextArea from "../Components/Inputs/TextArea";
import Dropdown from "../Components/Inputs/Dropdown";
import NavLink from "../Components/NavLink";
import ErrorMessage from "../Components/ErrorMessage";

class AccountApplication extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        };

        this.handlers = {};
        ["firstName", "lastName", "title", "company", "email", "mobilePhone", "businessPhone", "address1", "address2", "city", "state", "zipCode", "comments"].forEach(z => {
            this.handlers[`handle${z.slice(0,1).toUpperCase()}${z.slice(1, z.length)}Input`] = function(e){ this.setState({[z]: e.target.value}) }.bind(this);
        });

        this.handleCountryInput = this.handleCountryInput.bind(this);
        this.handleStateInput = this.handleStateInput.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormCancel = this.handleFormCancel.bind(this);

        this.checkFieldError = this.checkFieldError.bind(this);
    }

    handleCountryInput(country) {
        this.setState({country});
        this.props.actions.accountApplicationCountryInput(country);
    }
    handleStateInput(state) { this.setState({state}) }

    handleFormCancel(e){
        e.preventDefault();
        // Should this be handled with a custom action like the form submit below?
        let formCheck = true;
        if(Object.keys(this.state).length > 0) {
            // TODO: Replace with React-based modal dialog
            formCheck = window.confirm("If you cancel now, all your changes will be lost.  Are you sure you want to navigate away from this page?");
        }
        if(formCheck) this.props.actions.changePage("login");
    }

    handleFormSubmit(e){
        e.preventDefault();
        this.props.actions.submitAccountApplicationForm({...this.state});
    }

    getErrors() {
        const { appData } = this.props;

        if(appData.get('errors').size > 0) {
            let jsx = appData.get('errors').map((x, i) => {
                return <ErrorMessage message={x} key={i} />
            });
            return <div className='login_form_errors'>{jsx}</div>
        }
    }

    checkFieldError(field) {
        const { userData } = this.props;
        let errorFields = userData.get("errorFields").toArray ? userData.get("errorFields").toArray() : [];
        return errorFields.indexOf(field) >= 0;
    }

    componentWillMount() {
        this.props.actions.accountApplicationPageLoad();
    }

    buildTextField(fieldName, label) {
        return (
            <div className={"applicationCell"}>
                <Label>{label}</Label>
                <TextBox
                    id={fieldName}
                    className={"aur-text-box aur-form-element" + ( this.checkFieldError(fieldName) ? " aur-text-box-error" : "" )}
                    onChange={this.handlers[`handle${fieldName.slice(0, 1).toUpperCase()}${fieldName.slice(1)}Input`]}
                />
            </div>
        )
    }

    render() {
        const { appData, actions } = this.props;
        

        return (
            <div id="accountApplicationContainer">
                <div className={"logo"}>
                    <img src={this.props.logo} alt="Logo" />
                </div>
                <h4>Apply for Account</h4>
                {this.getErrors()}
                <div id="accountApplicationFormContainer">
                    <form onSubmit={this.handleFormSubmit}>
                        {this.buildTextField("firstName", "First Name")}
                        {this.buildTextField("lastName", "Last Name")}

                        {this.buildTextField("title", "Title")}
                        {this.buildTextField("company", "Company")}

                        {this.buildTextField("email", "Email Address")}
                        {this.buildTextField("mobilePhone", "Mobile Phone Number")}

                        {this.buildTextField("businessPhone", "Business Phone Number")}
                        <div className={"applicationCell"}>
                            <Label>Country</Label>
                            <Dropdown
                                id="country"
                                className={"aur-form-element" + ( this.checkFieldError("country") ? " select-error" : "" )}
                                onChange={this.handleCountryInput}
                                options={this.props.userData.get("countryOptions").toArray().map(x => x.toObject())}
                                value={this.state.country}
                            />
                        </div>

                        {this.buildTextField("address1", "Address 1")}
                        {this.buildTextField("address2", "Address 2")}

                        {this.buildTextField("city", "City")}
                        <div className={"applicationCell"}>
                            <div className={"accountApplicationStateSelect"}>
                                <Label>State</Label>
                                <Dropdown
                                    id="state" 
                                    className={"aur-form-element" + ( this.checkFieldError("state") ? " select-error" : "" )}
                                    onChange={this.handleStateInput}
                                    options={this.props.userData.get("stateOptions").toArray().map(x => x.toObject())}
                                    value={this.state.state}
                                />
                            </div>
                            <div className={"accountApplicationZipCode"}>
                                <Label>Zip Code</Label>
                                <TextBox
                                    id="zipCode" 
                                    className={"aur-text-box aur-form-element" + ( this.checkFieldError("zipCode") ? " aur-text-box-error" : "" )}
                                    onChange={this.handlers.handleZipCodeInput}
                                />
                            </div>
                        </div>

                        <div>
                            <Label>Comments</Label>
                            <TextArea 
                                id="comments"
                                className="aur-text-area aur-form-element"
                                onChange={this.handlers.handleCommentsInputs}
                            />
                        </div>
                        <br />
                        <div className="accountApplicationBtns">
                            <Button 
                                type='submit'
                                disabled={appData.get('isProcessing')} 
                                className="aur-button primary" >Submit
                            </Button>
                            <Button
                                className="aur-button secondary"
                                disabled={appData.get('isProcessing')} 
                                onClick={this.handleFormCancel}>Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AccountApplication;