
// TODO: Have this only contain appConstants?
export const SET_FORGOT_PASSWORD_PAGE = 'SET_FORGOT_PASSWORD_PAGE';
export const SET_LOGIN_PAGE = 'SET_LOGIN_PAGE';
export const SET_LOCKED_ACCOUNT_PAGE = 'SET_LOCKED_ACCOUNT_PAGE';

export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_LOGO = 'SET_LOGO';
export const SET_BACKGROUND = 'SET_BACKGROUND';
export const SET_BG_CYCLE_TIME = 'SET_BG_CYCLE_TIME';
export const SET_TOGGLES = 'SET_TOGGLES';

export const INFO = 'INFO';
export const ERROR = 'ERROR';

export const FORM_FAILURE = 'FORM_FAILURE';

export const CHANGE_PAGE = 'CHANGE_PAGE';

export const SET_SHOW_HINTS = 'SET_SHOW_HINTS';
export const SET_IS_PROCESSING = 'SET_IS_PROCESSING';
export const SET_IS_LOADING_INITIAL_STATE = 'SET_IS_LOADING_INITIAL_STATE';

export const START_PROCESSING = 'START_PROCESSING';
export const STOP_PROCESSING = 'STOP_PROCESSING';

export const SUBMIT_DOMAIN_FORM = 'SUBMIT_DOMAIN_FORM';
export const SUBMIT_DOMAIN_FORM_SUCCESS = 'SUBMIT_DOMAIN_FORM_SUCCESS';
export const SUBMIT_DOMAIN_FORM_FAILURE = 'SUBMIT_DOMAIN_FORM_FAILURE';

export const SUBMIT_LOGIN_FORM = "SUBMIT_LOGIN_FORM";
export const SUBMIT_LOGIN_FORM_FAILURE = "SUBMIT_LOGIN_FORM_FAILURE";
export const SUBMIT_LOGIN_SUCCESS_EXPIRED_PASSWORD = "SUBMIT_LOGIN_SUCCESS_EXPIRED_PASSWORD";
export const SUBMIT_LOGIN_SUCCESS_EXPIRED_OR_LOCKED_ACCOUNT = "SUBMIT_LOGIN_SUCCESS_EXPIRED_OR_LOCKED_ACCOUNT";
export const SUBMIT_LOGIN_FORM_SUCCESS = "SUBMIT_LOGIN_FORM_SUCCESS";

export const SUBMIT_RESET_PASSWORD_FORM = "SUBMIT_RESET_PASSWORD_FORM";
export const SUBMIT_RESET_PASSWORD_FORM_FAILURE = "SUBMIT_RESET_PASSWORD_FORM_FAILURE";
export const SUBMIT_RESET_PASSWORD_FORM_SUCCESS = "SUBMIT_RESET_PASSWORD_FORM_SUCESS";

export const SUBMIT_LOCKED_ACCOUNT_FORM = "SUBMIT_LOCKED_ACCOUNT_FORM";
export const SUBMIT_LOCKED_ACCOUNT_FORM_FAILURE = "SUBMIT_LOCKED_ACCOUNT_FORM_FAILURE";
export const SUBMIT_LOCKED_ACCOUNT_FORM_SUCCESS = "SUBMIT_LOCKED_ACCOUNT_FORM_SUCESS";

export const SUBMIT_FORGOT_PASSWORD_FORM = "SUBMIT_FORGOT_PASSWORD_FORM";
export const SUBMIT_FORGOT_PASSWORD_FORM_FAILURE = "SUBMIT_FORGOT_PASSWORD_FORM_FAILURE";
export const SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS = "SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS";

export const SUBMIT_FORGOT_PASSWORD_STEP_2_FORM = "SUBMIT_FORGOT_PASSWORD_STEP_2_FORM";
export const SUBMIT_FORGOT_PASSWORD_STEP_2_FORM_FAILURE = "SUBMIT_FORGOT_PASSWORD_STEP_2_FORM_FAILURE";
export const SUBMIT_FORGOT_PASSWORD_STEP_2_FORM_SUCCESS = "SUBMIT_FORGOT_PASSWORD_STEP_2_FORM_SUCCESS";

export const SUBMIT_ACCOUNT_APPLICATION_FORM = "SUBMIT_ACCOUNT_APPLICATION_FORM";
export const SUBMIT_ACCOUNT_APPLICATION_FORM_FAILURE = "SUBMIT_ACCOUNT_APPLICATION_FORM_FAILURE";
export const SUBMIT_ACCOUNT_APPLICATION_FORM_SUCCESS = "SUBMIT_ACCOUNT_APPLICATION_FORM_SUCCESS";

export const RECEIVE_SESSION = 'RECEIVE_SESSION';

export const environmentLevelLists = [
    {
      key : "qaint2",
      value : "QA"
    },
    {
      key : "uat3",
      value : "UAT"
    },
    {
      key : "uat4",
      value : "UAT"
    },
    {
      key : "uat2",
      value : "UAT"
    },
    {
      key : "uat",
      value : "UAT"
    },
    {
      key : "loattest",
      value : "QA"
    },
    {
      key : "prod",
      value : "PROD"
    },
    {
      key : "ps-dev",
      value : "PS DEV"
    },
    {
      key : "ps-ext",
      value : "PS EXT"
    },
    {
      key : "stage",
      value : "STAGE"
    },
    {
      key : "qaana1",
      value : "QA"
    },
    {
      key : "qaana2",
      value : "QA"
    },
    {
      key : "atcqa",
      value : "QA"
    },
    {
      key : "atcqa2",
      value : "QA"
    },
    {
      key : "12cauto1",
      value : "QA"
    },
    {
      key : "12cauto2",
      value : "QA"
    },
    {
      key : "12cauto3",
      value : "QA"
    },
    {
      key : "qaauto1",
      value : "QA"
    },
    {
      key : "qaauto1",
      value : "QA"
    },
    {
      key : "qaauto1",
      value : "QA"
    },
    {
      key : "loadtest19",
      value : "qa"
    },
    {
      key : "qa",
      value : "QA"
    },
    {
      key : "dev",
      value : "DEV"
    }
  ]